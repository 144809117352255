<template>
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.0003 9.33317C17.691 9.33317 9.33366 17.6905 9.33366 27.9998C9.33366 38.3092 17.691 46.6665 28.0003 46.6665C38.3096 46.6665 46.667 38.3092 46.667 27.9998C46.667 17.6905 38.3096 9.33317 28.0003 9.33317ZM4.66699 27.9998C4.66699 15.1132 15.1137 4.6665 28.0003 4.6665C40.887 4.6665 51.3337 15.1132 51.3337 27.9998C51.3337 40.8865 40.887 51.3332 28.0003 51.3332C15.1137 51.3332 4.66699 40.8865 4.66699 27.9998Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.1436 29.5556C26.1436 29.5556 26.8333 32.6667 28 32.6667C29.1667 32.6667 29.8111 29.5556 29.8111 29.5556C29.8111 29.5556 31.5 21 31.5 18.6667C31.5 16.3333 30.3333 14 28 14C25.6667 14 24.5 16.3333 24.5 18.6667C24.5 21 26.1436 29.5556 26.1436 29.5556ZM28.0116 39.6667C29.2939 39.6667 30.3333 38.622 30.3333 37.3333C30.3333 36.0447 29.2939 35 28.0116 35H27.9884C26.7061 35 25.6667 36.0447 25.6667 37.3333C25.6667 38.622 26.7061 39.6667 27.9884 39.6667H28.0116Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'WarningSvg',
};
</script>
