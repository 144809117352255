const serverUrl = process.env.VUE_APP_SERVER_URL || 'http://localhost:3000';
console.log('serverUrl', serverUrl);
// const cors = 'no-cors';
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

async function get(router, endpoint, params) {
  const builtParams = new URLSearchParams(params).toString();
  const result = await fetch(
    `${serverUrl}/${router}/${endpoint}?${builtParams}`,
    {
      headers,
      method: 'GET',
    }
  );
  return result.json();
}

async function post(router, endpoint, body) {
  const result = await fetch(`${serverUrl}/${router}/${endpoint}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });

  return result.json();
}

// async function put(router, endpoint, body) {
//   const result = await fetch(`${serverUrl}/${router}/${endpoint}`, {
//     method: 'PUT',
//     headers,
//     body: JSON.stringify(body),
//   });

//   return result.json();
// }

function getServerUrl() {
  return serverUrl;
}

export default {
  get,
  post,
  getServerUrl,
  // put,
  // del,
};
