<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="&#208;&#161;&#208;&#187;&#208;&#190;&#208;&#185; 3">
      <g id="Group">
        <g id="Group_2">
          <path
            id="Vector"
            d="M20.9999 41.5716C9.65677 41.5716 0.428467 32.3433 0.428467 21.0001C0.428467 9.65701 9.65677 0.428711 20.9999 0.428711C32.343 0.428711 41.5713 9.65701 41.5713 21.0001C41.5713 32.3433 32.343 41.5716 20.9999 41.5716ZM20.9999 2.63267C10.8732 2.63442 2.63417 10.8735 2.63417 21.0001C2.63417 31.1268 10.8732 39.3659 20.9999 39.3659C31.1266 39.3659 39.3656 31.1268 39.3656 21.0001C39.3656 10.8735 31.1266 2.63442 20.9999 2.63442V2.63267Z"
          />
          <path
            id="Vector_2"
            d="M7.26279 7.25332L5.70312 8.81299L33.2111 36.321L34.7708 34.7613L7.26279 7.25332Z"
          />
        </g>
        <g id="Group_3">
          <path
            id="Vector_3"
            d="M21.0384 31.9761C19.3308 31.9761 17.7403 31.2141 16.6672 29.8823L12.268 24.4135L12.2138 24.1531C11.9167 22.7146 12.1614 19.6875 15.2165 18.5287L15.4053 18.457H25.892C26.0878 18.4605 27.6992 18.5217 28.823 19.8535C29.6865 20.8777 29.9836 22.3057 29.7057 24.0989L29.662 24.3803L25.4778 29.7967C24.4204 31.1652 22.8211 31.9604 21.0926 31.9761C21.0751 31.9761 21.0559 31.9761 21.0367 31.9761H21.0384ZM14.3426 23.4732L18.3853 28.4998C19.0442 29.3178 20.0055 29.8054 21.0716 29.7704C22.1203 29.76 23.0921 29.2776 23.7335 28.4474L27.5629 23.4889C27.645 22.7548 27.6188 21.86 27.1504 21.2919C26.6645 20.7012 25.8693 20.6627 25.8605 20.661H15.8248C14.3007 21.3461 14.2815 22.8615 14.3409 23.4732H14.3426Z"
          />
          <g id="Group_4">
            <path
              id="Vector_4"
              d="M20.7535 11.5798C20.7535 13.2909 19.8953 14.6786 18.8379 14.6786C17.7805 14.6786 16.9224 13.2909 16.9224 11.5798C16.9224 9.8687 17.7805 8.48096 18.8379 8.48096C19.8953 8.48096 20.7535 9.8687 20.7535 11.5798Z"
            />
            <path
              id="Vector_5"
              d="M25.0182 11.5798C25.0182 13.2909 24.16 14.6786 23.1026 14.6786C22.0452 14.6786 21.187 13.2909 21.187 11.5798C21.187 9.8687 22.0452 8.48096 23.1026 8.48096C24.16 8.48096 25.0182 9.8687 25.0182 11.5798Z"
            />
            <path
              id="Vector_6"
              d="M29.5885 14.6784C29.5885 16.3895 28.7303 17.7772 27.6729 17.7772C26.6155 17.7772 25.7573 16.3895 25.7573 14.6784C25.7573 12.9673 26.6155 11.5796 27.6729 11.5796C28.7303 11.5796 29.5885 12.9673 29.5885 14.6784Z"
            />
            <path
              id="Vector_7"
              d="M16.1778 14.6784C16.1778 16.3895 15.3197 17.7772 14.2623 17.7772C13.2048 17.7772 12.3467 16.3895 12.3467 14.6784C12.3467 12.9673 13.2048 11.5796 14.2623 11.5796C15.3197 11.5796 16.1778 12.9673 16.1778 14.6784Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PestFilterSvg',
};
</script>

<style scoped></style>
