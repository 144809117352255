<template>
  <div>
    <BaseSelect :items="languages" v-model="selectedLanguage" @change="changeLanguage" />
  </div>
</template>

<script>
import BaseSelect from './base/BaseSelect.vue';

const languageCodes = {
  English: 'en',
  Українська: 'ua',
};
const languageNames = {
  en: 'English',
  ua: 'Українська',
};
import vuetifyLocaleMap from '@/constants/vuetifyLanguagesMap';

export default {
  name: 'LanguageSelector',
  components: {
    BaseSelect,
  },
  data() {
    const selectedLanguage = languageNames[this.$i18n.locale];
    return {
      languages: ['English', 'Українська'],
      selectedLanguage,
    };
  },
  methods: {
    changeLanguage(languageName) {
      const languageCode = languageCodes[languageName];
      this.$i18n.locale = languageCode;
      this.$vuetify.locale.current = vuetifyLocaleMap[languageCode];
      localStorage.setItem('selectedLanguage', languageCode);
    },
  },
  watch: {
    selectedLanguage(newLang) {
      this.changeLanguage(newLang);
    },
  },
};
</script>
