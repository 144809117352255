<template>
  <div class="base-date-picker-wrapper">
    <span class="base-input-label" v-if="inputLabel">{{ $t(inputLabel) }}</span>
    <v-text-field :placeholder="placeholderDate" @click="showDialog" class="date-picker-activator" variant="outlined"
      hide-details :readonly="true">
      <template v-slot:prepend-inner>
        <v-icon icon="mdi-calendar-range" />
      </template>
      <template v-slot:append-inner>
        <v-icon icon="mdi-chevron-down" />
      </template>
    </v-text-field>
    <BaseDialog width="500" v-model="isVisible" class="date-picker-wrapper">
      <v-date-picker v-model="baseValue" class="base-date-picker" bg-color="#2F313D" color="#A594FD"
        input-mode="calendar" :min="min">
        <template v-slot:actions>
          <div class="action-ok px-4 pb-2" @click="closeDialog">OK</div>
        </template>
      </v-date-picker>
    </BaseDialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog.vue';
export default {
  name: 'BaseDatePicker',
  components: {
    BaseDialog,
  },
  props: {
    modelValue: {
      type: Date,
      default: new Date(),
    },
    min: {
      type: Date,
      default: null,
    },
    inputLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      baseValue: this.modelValue,
      isVisible: false,
    };
  },
  methods: {
    cancel() {
      this.baseValue = this.modelValue;
      this.closeDialog();
    },
    showDialog() {
      this.isVisible = true;
    },
    closeDialog() {
      this.isVisible = false;
    },
  },
  watch: {
    baseValue() {
      this.$emit('update:modelValue', this.baseValue);
    },
  },
  computed: {
    placeholderDate() {
      return this.baseValue.toLocaleDateString();
    },
  },
};
</script>

<style lang="scss">
.date-picker-wrapper {

  .v-picker .v-picker__header,
  .v-picker .v-picker-title {
    display: none;
  }

  .date-picker-activator {
    cursor: pointer;
  }

  .v-btn--variant-elevated,
  .v-btn--variant-flat {
    background-color: #2f313d;
    color: white;
  }

  .action-ok {
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    color: rgb(var(--v-theme-primary));
  }
}

.date-picker-activator .v-field__field input.v-field__input {
  min-height: 44px;
  max-height: 44px;
  height: 44px;
  padding: 10px 12px;
}


.v-date-picker-month__weekday {
  font-size: 14px;
  font-weight: 600;
  opacity: 0.5;
}

.date-picker-activator .v-field__outline {
  border-radius: 8px;
}
</style>
