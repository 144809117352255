import debounce from 'lodash/debounce';
let AutocompleteService;

export default {
  data() {
    return {
      locationPredictions: [],
      locationText: '',
      place_id: '',
      debouncedLocationHandler: debounce(this.locationChangeHandler, 500),
    };
  },
  mounted() {
    if (window.google?.maps) {
      AutocompleteService = new window.google.maps.places.AutocompleteService();
    }
  },
  methods: {
    locationChangeHandler(inputEvent, options = {}) {
      if (!window.google?.maps) {
        return;
      }

      if (!AutocompleteService) {
        AutocompleteService =
          new window.google.maps.places.AutocompleteService();

        if (!AutocompleteService) {
          return;
        }
      }

      const input = inputEvent.target.value;

      if (!input) {
        this.locationPredictions = [];
        return;
      }

      const trimCity = options.trimCity || false;

      if (trimCity) {
        delete options.trimCity;
      }

      const languageCode = this.$vuetify.locale.current;

      const serviceOptions = {
        language: languageCode,
        ...options,
      };

      AutocompleteService.getPlacePredictions(
        { input, ...serviceOptions },
        (predictions) => {
          this.locationPredictions = predictions || [];
        }
      );
    },
    selectLocation(prediction, shouldTrimCity = false) {
      // "prediction" is a suggested google autocomplete item based on user input
      if (shouldTrimCity) {
        this.locationText = prediction.description.split(',')[0];
      } else if (
        prediction.description &&
        prediction.description.split(',').length > 4
      ) {
        this.locationText = prediction.description
          .split(',')
          .slice(0, 3)
          .join(',');
      } else {
        this.locationText = prediction.description;
      }
      this.place_id = prediction.place_id;
      this.locationPredictions = [];
    },
  },
};
