<template>
  <v-dialog class="base-dialog" v-model="isVisible" v-bind="$attrs">
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    bodyClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isVisible: this.modelValue,
    };
  },
  watch: {
    modelValue() {
      this.isVisible = this.modelValue;

      if (this.isVisible && this.bodyClass) {
        document.body.classList.add(this.bodyClass);
      } else if (!this.isVisible && this.bodyClass) {
        document.body.classList.remove(this.bodyClass);
      }
    },
    isVisible() {
      this.$emit('update:modelValue', this.isVisible);
    },
  },
};
</script>

<style lang="scss">
.base-dialog {
  .v-overlay__scrim {
    background: transparent;
  }
}

.v-overlay--active.base-dialog {
  backdrop-filter: blur(2px);
}
</style>
