<template>
  <div v-if="isLoaded" class="profile-page-wrapper">
    <PhotoBlock @photo-uploaded="onPhotoUpload" :image="image" :can-upload="isCurrentUsersProfile && !image" />
    <!-- <div class="rating">
      <span class="rating-value">{{ rating }}</span>
      <StarSvg />
    </div> -->
    <div class="my-profile-controls py-3" v-if="isCurrentUsersProfile">
      <BaseButton @click="editProfile">{{ $t('button.edit') }}</BaseButton>
      <BaseButton @click="searchUsers">{{
        $t('button.findFriends')
      }}</BaseButton>
    </div>
    <div class="following-relations py-3" v-else-if="!user.isDeleted && !isBlockedByMe">
      <BaseButton :class="{ primary: !isFollowing }" @click="changeFollowingStatus">
        <span v-if="isFollowing">{{ $t('button.following') }}</span>
        <span v-else>{{ $t('button.follow') }}</span>
      </BaseButton>
    </div>
    <h1 class="deleted-profile-text text-center py-2" v-if="user.isDeleted">
      {{ $t('cardText.thisAccountIsDeleted') }}
    </h1>
    <h3 class="deleted-profile-text text-center py-2" v-else-if="isBlockedByMe">
      {{ $t('cardText.blockedUserProfile') }}
    </h3>
    <div class="following-and-events-lists pt-1 pb-3" :class="{ 'no-actions pt-2': user.isDeleted }"
      v-if="!isBlockedByMe">
      <div class="stat following" @click="openFollowingList">
        <div class="value">{{ user?.following?.length }}</div>
        <div class="label">{{ $t('label.following') }}</div>
      </div>
      <div class="stat followers" @click="openFollowersList">
        <div class="value">{{ user?.followers?.length }}</div>
        <div class="label">{{ $t('label.followers') }}</div>
      </div>
      <div class="stat events" @click="openEventsList">
        <div class="value">0</div>
        <div class="label">{{ $t('label.events') }}</div>
      </div>
    </div>
    <div class="tabs-block d-flex justify-space-around mt-6 mb-3" v-if="!isBlockedByMe">
      <div class="tab" @click="currentTab = TabsEnum.GAMES" :class="{ 'is-active-tab': currentTab === TabsEnum.GAMES }">
        {{ $t('label.games') }}
      </div>
      <div class="tab" @click="currentTab = TabsEnum.EVENTS"
        :class="{ 'is-active-tab': currentTab === TabsEnum.EVENTS }">
        {{ $t('label.events') }}
      </div>
      <div class="tab" @click="currentTab = TabsEnum.INFO" :class="{ 'is-active-tab': currentTab === TabsEnum.INFO }">
        {{ $t('label.info') }}
      </div>
    </div>
    <v-window class="profile-category-details tab-wrapper" v-model="currentTab" v-if="!isBlockedByMe">
      <v-window-item :value="TabsEnum.GAMES">
        <div class="games-list" v-if="favoriteGames.length">
          <GameListItem v-for="gameId in favoriteGames" :key="gameId" :game-id="gameId"
            @changeFavoriteStatus="toggleFavoriteStatus(gameId)" :is-favorite="isGameFavorite(gameId)"
            :hide-favorite="isMyProfile" class="my-3 py-3 bordered" />
        </div>
        <div class="empty-info" v-else>
          <div class="label">{{ $t('cardText.thereNoFavoriteGames') }}</div>
        </div>
      </v-window-item>

      <v-window-item :value="TabsEnum.EVENTS">
        <div class="events-list" v-if="hosted.length || joined.length">
          <div class="label-bordered" v-if="hosted.length">
            {{ $t('label.hosts') }}
          </div>
          <div class="events">
            <EventListItem v-for="event in hosted" :key="event._id" :item="event" class="my-3 py-3 bordered" />
          </div>
          <div class="label-bordered" v-if="joined.length">
            {{ $t('label.joined') }}
          </div>
          <div class="events">
            <EventListItem v-for="event in joined" :key="event._id" :item="event" class="my-3 py-3 bordered" />
          </div>
        </div>
        <div class="empty-info" v-else>
          <div class="label">{{ $t('cardText.thereAreNoActiveEvents') }}</div>
        </div>
      </v-window-item>

      <v-window-item :value="TabsEnum.INFO" class="info-tab">
        <div class="user-info" v-if="!isEmptySocials">
          <div class="label-bordered">{{ $t('label.socials') }}</div>
          <div class="social-link" v-if="socials.bgg">
            <div class="network-name">BGG:</div>
            <div class="username" @click="openBgg">{{ socials.bgg }}</div>
          </div>
          <div class="social-link" v-if="socials.telegram">
            <div class="network-name">Telegram:</div>
            <div class="username" @click="openTelegram">
              {{ socials.telegram }}
            </div>
          </div>
          <div class="social-link" v-if="socials.instagram">
            <div class="network-name">Instagram:</div>
            <div class="username" @click="openInstagram">
              {{ socials.instagram }}
            </div>
          </div>
        </div>
        <div class="about-user pt-2" v-if="user?.about?.length">
          <div class="label-bordered">{{ $t('label.about') }}</div>
          <div class="about">{{ user.about }}</div>
        </div>
        <div class="empty-info" v-else-if="!user?.about?.length && isEmptySocials">
          <div class="label">
            {{ $t('cardText.theseDetailsAreNotYetFilled') }}
          </div>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue';
import PhotoBlock from '@/components/PhotoBlock.vue';
// import StarSvg from '@/components/icons/StarSvg.vue';
import GameListItem from '@/components/GameListItem.vue';
import EventListItem from '@/components/EventListItem.vue';
import gameListItemMixin from '@/mixins/gameListItemMixin';

const TabsEnum = {
  GAMES: 'games',
  EVENTS: 'events',
  INFO: 'info',
};

export default {
  name: 'ProfilePage',
  mixins: [gameListItemMixin],
  components: {
    BaseButton,
    PhotoBlock,
    // StarSvg,
    EventListItem,
    GameListItem,
  },
  data() {
    const userId = this.$route.params.id;
    const user = userId ? null : this.$store.getters['UserStore/getUser'];
    const isMyProfile = !userId;
    return {
      user,
      isMyProfile,
      image: user?.avatar || null,
      isLoaded: !!user,
      TabsEnum,
      currentTab: TabsEnum.Games,
    };
  },
  async mounted() {
    try {
      if (!this.isLoaded) {
        this.$store.dispatch('UserStore/fetchFavoriteGames', {
          userId: this.$route.params.id,
        });
        await this.$store.dispatch(
          'UserStore/getUserById',
          this.$route.params.id
        );
        this.user = this.$store.getters['UserStore/getUserById'](
          this.$route.params.id
        );
        this.image = this.user.avatar;
        this.isLoaded = true;
      } else {
        this.$store.dispatch('UserStore/refreshFavoriteGames', {
          userId: this.user._id,
        });
      }

      const userId = this.isMyProfile ? this.user._id : this.$route.params.id;
      this.$store.dispatch('UserStore/fetchEventsByUserId', {
        userId,
      });
      this.$store.dispatch('UserStore/fetchSocialSettings', {
        userId,
      });
    } catch (error) {
      window.console.log(error);
    }
  },
  methods: {
    async onPhotoUpload(image) {
      this.image = image;
      await this.$store.dispatch('UserStore/setUserAvatar', {
        userId: this.user._id,
        avatar: image,
      });
    },
    searchUsers() {
      const searchState = {
        searchQuery: '',
        currentTab: 'users',
      };
      this.$store.dispatch('SearchStore/saveSearchState', searchState);
      this.$router.push({ name: 'search' });
    },
    logOut() {
      this.$store.dispatch('UserStore/logout');
      this.$router.push({ name: 'login' });
    },
    editProfile() {
      this.$router.push({ name: 'editProfile' });
    },
    changeFollowingStatus() {
      try {
        this.$store.dispatch('UserStore/changeFollowingStatus', {
          userId: this.user._id,
        });
      } catch (error) {
        window.console.log(error);
      }
    },
    openInstagram() {
      const url = `https://instagram.com/${this.socials.instagram}`;
      window.open(url, '_blank');
    },
    openBgg() {
      const url = `https://boardgamegeek.com/user/${this.socials.bgg}`;
      window.open(url, '_blank');
    },
    openTelegram() {
      const url = `https://t.me/${this.socials.telegram}`;
      window.open(url, '_blank');
    },
    openFollowingList() {
      this.$store.commit(
        'SettingsStore/setUsersListPageTitle',
        this.$t('label.following')
      );
      this.$store.dispatch('UserStore/setUsersPageList', this.user.following);
      this.$router.push({ name: 'usersList' });
    },
    openFollowersList() {
      this.$store.commit(
        'SettingsStore/setUsersListPageTitle',
        this.$t('label.followers')
      );
      this.$store.dispatch('UserStore/setUsersPageList', this.user.followers);
      this.$router.push({ name: 'usersList' });
    },
    openEventsList() {
      //TODO: implement
    },
  },
  computed: {
    isCurrentUsersProfile() {
      const user = this.$store.getters['UserStore/getUser'];
      return !!this.isLoaded && user._id === this.user._id;
    },
    rating() {
      return this.isLoaded ? this.user?.rating : 0;
    },
    isFollowing() {
      return this.$store.getters['UserStore/isFollowing'](this.user._id);
    },
    socials() {
      return this.$store.getters['UserStore/getSocials'];
    },
    isEmptySocials() {
      return (
        !this.socials || !Object.values(this.socials).filter((v) => !!v).length
      );
    },
    isBlockedByMe() {
      return this.$store.getters['UserStore/isBlockedByMe'](this.user._id);
    },
    hosted() {
      return this.$store.getters['UserStore/getHostedEvents'];
    },
    joined() {
      return this.$store.getters['UserStore/getJoinedEvents'];
    },
    favoriteGames() {
      return this.isMyProfile
        ? this.$store.getters['UserStore/getFavoriteGames']
        : this.$store.getters['UserStore/getFavoriteByUserId'](this.user._id);
    },
  },
};
</script>

<style lang="scss">
.profile-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .rating {
    display: flex;
    align-items: center;
    margin-top: 16px;

    svg {
      fill: rgb(var(--v-theme-primary));
      width: 20px;
      height: 20px;
    }
  }

  .profile-category-details {
    display: flex;
    flex: 1 1 auto;
    overflow-y: scroll;
    width: 100%;
    padding: 0 12px;
    min-height: 0;
  }

  .v-window__container {
    width: 100%;
  }

  .games-list {
    width: 100%;
  }

  .tabs-block {
    width: 90%;
    display: flex;
  }

  .tab {
    font-size: 16px;
    line-height: 16px;
    width: 30%;
    display: flex;
    text-transform: capitalize;
    justify-content: center;
    cursor: pointer;
  }

  .empty-info {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 16px;
  }

  .tab:not(.is-active-tab) {
    transition: font-size 0.1s ease-out;
  }

  .tab-name {
    text-transform: capitalize;
  }

  .is-active-tab {
    color: rgb(var(--v-theme-primary));
    font-size: 20px;
    line-height: 20px;
    transition: font-size 0.3s ease-in;
  }

  .my-profile-controls {
    display: flex;
    justify-content: space-around;
    width: 100%;

    font-weight: 400;

    .base-button {
      width: 40%;
    }
  }

  .following-relations {
    display: flex;
    justify-content: space-around;
    width: 100%;

    font-weight: 400;

    .base-button {
      width: 90%;
    }
  }

  .user-info {
    padding-top: 12px;

    .social-link {
      display: flex;
      margin: 5px;

      .network-name,
      .username {
        color: #9f9f9f;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
      }

      .network-name {
        padding-inline-end: 10px;
      }

      .username {
        color: rgb(var(--v-theme-primary));
      }
    }
  }

  .events-list {
    padding-top: 12px;
  }

  .events-list .label-bordered,
  .info-tab .label-bordered {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgb(63, 65, 71);
  }

  .following-and-events-lists {
    display: flex;
    justify-content: space-around;
    width: 90%;
    font-weight: 400;
    border-bottom: 1px solid rgb(63, 65, 71);

    &.no-actions {
      pointer-events: none;
    }


    .stat {
      width: 30%;
      text-align: center;
      cursor: pointer;
    }

    .value {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .label {
      font-size: 12px;
      line-height: 12px;
    }
  }

  .logout {
    margin-top: 16px;
  }
}
</style>
