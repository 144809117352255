<template>
  <div class="reschedule-dialog">
    <BaseDialog v-model="isDialogActive">
      <v-card class="pb-8 px-8">
        <v-card-title class="text-h6 pt-8 pb-8 text-center">{{ $t('cardText.rescheduleEvent') }}</v-card-title>
        <BaseDatePicker v-model="date" :min="minDate" />
        <div class="time-pickers mt-4 mb-2 d-flex justify-space-between">
          <div class="time-picker-wrapper">
            <v-icon icon="mdi-clock-outline me-2" />
            <span>{{ $t('label.start') }}</span>
            <input class="ms-2" type="time" name="start" id="startTime" ref="startTime" :value="startsAt"
              @input="startsAt = $event.target.value" step="60" pattern="[0-9]{2}:[0-9]{2}" />
          </div>

          <div class="time-picker-wrapper">
            <v-icon icon="mdi-clock-outline me-2" />
            <span>{{ $t('label.end') }}</span>
            <input class="ms-2" type="time" name="end" ref="endTime" :value="endsAt" id="endTime" step="60"
              @input="endsAt = $event.target.value" pattern="[0-9]{2}:[0-9]{2}" />
          </div>
        </div>
        <div v-if="!isValid" class="error justify-center mt-4">
          {{ validationMessage }}
        </div>
        <div class="d-flex justify-center mt-4">
          <BaseButton color="primary" @click="reschedule">{{ $t('button.reschedule') }}</BaseButton>
        </div>
      </v-card>
    </BaseDialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseDatePicker from '@/components/base/BaseDatePicker.vue';

export default {
  name: 'RescheduleDialog',
  components: {
    BaseDialog,
    BaseButton,
    BaseDatePicker,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    const startTime = new Date(this.event.startsAt);
    const endTime = new Date(this.event.endsAt);

    let startHours = '0' + startTime.getHours();
    let startMinutes = '0' + startTime.getMinutes();

    let endHours = '0' + endTime.getHours();
    let endMinutes = '0' + endTime.getMinutes();

    const startsAt = `${startHours.substr(-2)}:${startMinutes.substr(-2)}`;
    const endsAt = `${endHours.substr(-2)}:${endMinutes.substr(-2)}`;

    const date = new Date(this.event.startsAt);

    const minDate = new Date(
      new Date(this.event.startsAt).getTime() - 24 * 60 * 60 * 1000
    );
    return {
      date,
      endsAt,
      minDate,
      startsAt,
      isValid: true,
      validationMessage: '',
      isDialogActive: this.modelValue,
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.isDialogActive = value;
      },
    },
    isDialogActive(value) {
      this.$emit('update:modelValue', value);
    },
  },
  methods: {
    reschedule() {
      this.isValid = true;
      const startsAt = new Date(this.date);
      startsAt.setHours(this.startsAt.split(':')[0]);
      startsAt.setMinutes(this.startsAt.split(':')[1]);

      const endsAt = new Date(this.date);
      endsAt.setHours(this.endsAt.split(':')[0]);
      endsAt.setMinutes(this.endsAt.split(':')[1]);

      const isStartsAtValid = startsAt > new Date();

      const isEndsAtValid = endsAt > new Date();

      if (!isStartsAtValid || !isEndsAtValid) {
        this.isValid = false;
        this.validationMessage = this.$t('validation.pleaseSelectTime');
        return;
      }

      const isValidTimeRange =
        new Date(
          `${this.date.toISOString().substr(0, 10)}T${this.startsAt}:00`
        ) <
        new Date(`${this.date.toISOString().substr(0, 10)}T${this.endsAt}:00`);

      if (!isValidTimeRange) {
        this.isValid = false;
        this.validationMessage = this.$t('validation.startTimePrecede');
        return;
      }

      this.$emit('reschedule', {
        date: new Date(this.date),
        startsAt,
        endsAt,
      });

      this.isDialogActive = false;
    },
  },
};
</script>

<style lang="scss"></style>
