<template>
  <BaseDialog v-model="isDialogVisible" title="Invite friends" height="75%">
    <v-card class="pb-8 invite-popup-content">
      <v-card-text class="pt-6 pb-6 px-4 popup-title">{{ $t('cardText.inviteUserToEvent') }}</v-card-text>
      <BaseInput v-model="searchQuery" label="" @input="debouncedSearch" placeholder="placeholder.searchUsers"
        class="px-8" />

      <div class="users-list mt-2 px-8" v-if="isLoaded">
        <UserListItem class="my-2" v-for="user in usersList" :key="user._id" :item="user" @inviteUser="inviteUser"
          :isInviteView="true" />
      </div>
      <v-skeleton-loader v-else-if="!isLoaded" v-for="i in 10" class="px-8" :key="i" color="background" :height="150"
        type="list-item-avatar-three-line" />
    </v-card>
  </BaseDialog>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import UserListItem from '@/components/UserListItem.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';
const debounce = require('lodash/debounce');

export default {
  name: 'InvitePopup',
  components: {
    BaseDialog,
    BaseInput,
    UserListItem,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      usersList: [],
      searchQuery: '',
      isLoaded: false,
      debouncedSearch: debounce(this.searchUsers, 500),
      isDialogVisible: this.modelValue,
    };
  },
  async mounted() {
    this.isLoaded = false;
    this.usersList = await this.$store.dispatch(
      'UserStore/fetchUsersList',
      this.userIds
    );
    this.isLoaded = true;
  },
  computed: {
    userIds() {
      return this.$store.getters['UserStore/getUsersInviteList'];
    },
  },
  watch: {
    modelValue() {
      this.isDialogVisible = this.modelValue;
    },
    isDialogVisible() {
      this.$emit('update:modelValue', this.isDialogVisible);
    },
  },
  methods: {
    async inviteUser(userId) {
      await this.$store.dispatch('EventStore/inviteUser', {
        eventId: this.eventId,
        userId,
      });
    },
    async searchUsers() {
      this.isLoaded = false;

      if (!this.searchQuery) {
        this.usersList = await this.$store.dispatch(
          'UserStore/fetchUsersList',
          this.userIds
        );
        this.isLoaded = true;
        return;
      }

      this.usersList = await this.$store.dispatch(
        'UserStore/fetchUsersByQuery',
        { query: this.searchQuery }
      );
      this.isLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.invite-popup-content {
  overflow: hidden;
  height: 100%;

  .popup-title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  .users-list {
    height: 100%;
    overflow-y: auto;
  }
}
</style>
