export default class GameInfoView {
  constructor(game = {}) {
    this.id = game.id || "";
    this.gameName = game.name || "";
    this.ukrainianName = game.ukrainianName || "";
    this.thumbnailUrl = game.thumbnail || "";
    this.minPlayers = game.minPlayers || "";
    this.maxPlayers = game.maxPlayers || "";
    this.minPlayTime = game.minPlayTime || "";
    this.maxPlayTime = game.maxPlayTime || "";
    this.minAge = game.minAge || "";
    this.description = game.description || "";
  }
}
