<template>
  <div class="base-switch">
    <v-switch v-model="baseValue" color="primary" hide-details :disabled="disabled" />
  </div>
</template>

<script>
export default {
  name: 'BaseSwitch',
  data() {
    return {
      baseValue: this.modelValue,
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modelValue(val) {
      this.baseValue = val;
    },
    baseValue(val) {
      this.$emit('update:modelValue', val);
    },
  },
};
</script>

<style lang="scss">
.base-switch {
  .v-selection-control__input:hover::before {
    opacity: 0;
  }
}
</style>
