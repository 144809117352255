<template>
  <div class="login-page px-3">
    <div class="logo-block">
      <img id="logo" src="@/assets/images/geeker-logo.svg" />
      <span class="main-label">GEEKER</span>
    </div>
    <EmailLoginFormVue ref="loginForm" />
    <div class="login-controls">
      <BaseButton @click="submitForm" class="primary my-4">{{
        $t('button.logIn')
      }}</BaseButton>
      <div class="divider-wrapper">
        <span class="divider" />
        {{ $t('other.or') }}
        <span class="divider" />
      </div>
      <BaseButton v-if="isIOS" @click="appleAuth" class="my-3 google-button">
        <div class="button-icon">
          <AppleSvg />
        </div>
        {{ $t('button.continueWithApple') }}
      </BaseButton>
      <BaseButton v-if="!isWeb" @click="googleAuth" class="my-3 apple-button">
        <div class="button-icon">
          <GoogleSvg />
        </div>
        {{ $t('button.continueWithGoogle') }}
      </BaseButton>
    </div>
    <div class="sign-up-link">
      <span>{{ $t('cardText.newToGeeker') }}</span>
      <span class="emphasized-text ps-2" @click="goToSignUp">
        {{ $t('button.createAnAccount') }}</span>
    </div>
    <BaseDialog v-model="isDialogVisible" location="center" color="#2F313D" persistent class="pa-2 login-page-dialog">
      <v-card class="pa-8">
        <div class="dialog-text social">{{ dialogMessage }}</div>
      </v-card>
    </BaseDialog>
  </div>
</template>

<script>
import ServerRequest from '@/utils/ServerRequest';
import decryptionUtils from '@/utils/decryptionUtils';
import BaseDialog from '@/components/base/BaseDialog.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import EmailLoginFormVue from '@/components/EmailLoginForm.vue';
import AppleSvg from '@/components/icons/AppleSvg.vue';
import GoogleSvg from '@/components/icons/GoogleSvg.vue';

export default {
  name: 'LoginPage',
  components: {
    AppleSvg,
    GoogleSvg,
    BaseDialog,
    BaseButton,
    EmailLoginFormVue,
  },
  data() {
    return {
      isDialogVisible: false,
      isIOS: false,
      platform: 'web',
      isWeb: true,
      dialogMessage: '',
    };
  },
  mounted() {
    document.addEventListener('deviceready', () => {
      this.isIOS = window.cordova.platformId === 'ios';
      this.platform = window.cordova.platformId;
      this.isWeb = false;

      if (this.isIOS) {
        window.WkWebView.allowsBackForwardNavigationGestures(false);
      }
    });
  },
  beforeUnmount() {
    if (this.isIOS) {
      window.WkWebView.allowsBackForwardNavigationGestures(true);
    }
  },
  methods: {
    showDialog(message) {
      this.dialogMessage = message;
      this.isDialogVisible = true;
    },
    clearDialog() {
      this.dialogMessage = '';
      this.isDialogVisible = false;
    },
    goToSignUp() {
      this.$router.push({ name: 'signUp' });
    },
    async submitForm() {
      this.$refs.loginForm.v$.$touch();
      if (this.$refs.loginForm.v$.$error) return;

      await this.loginWithCredentials();
    },
    async loginWithCredentials() {
      try {
        const { email, password } = this.$refs.loginForm.$data;

        const result = await this.$store.dispatch('UserStore/login', {
          email,
          password,
        });

        if (result) {
          this.$router.push({ name: 'search' });
        } else {
          this.showDialog(this.$t('dialog.invalidEmailOrPassword'));
          setTimeout(() => {
            this.clearDialog();
          }, 2000);
        }
      } catch (error) {
        window.console.log(error);
      }
    },
    async appleAuth() {
      try {
        window.FirebasePlugin.authenticateUserWithApple(
          async (credential) => {
            const { idToken, familyName, givenName } = credential;
            const { isLoggedIn, isNewUser } = await this.$store.dispatch(
              'UserStore/loginWithApple',
              {
                idToken,
                familyName,
                givenName,
              }
            );

            if (isLoggedIn) {
              if (isNewUser) {
                this.$store.commit('UserStore/setShouldOpenIdentityForm', true);
                this.$router.push({
                  name: 'signUp',
                });
              } else {
                this.$router.push({ name: 'search' });
              }
            }
          },
          (error) => {
            console.error(
              this.$t('validation.failedAuthenticateApple') + error
            );
          }
        );
      } catch (error) {
        window.console.log(error);
      }
    },
    async googleAuth() {
      const result = await ServerRequest.get('options', 'google-oauth-id', {
        platform: this.platform,
      });
      const clientId = await decryptionUtils.decryptApiKey(result.encryptedKey);
      window.FirebasePlugin.authenticateUserWithGoogle(
        clientId,
        async (credential) => {
          const { idToken, id } = credential;
          const { isLoggedIn, isNewUser } = await this.$store.dispatch(
            'UserStore/loginWithGoogle',
            {
              idToken,
              id,
            }
          );

          if (isLoggedIn) {
            if (isNewUser) {
              this.$store.commit('UserStore/setShouldOpenIdentityForm', true);
              this.$router.push({
                name: 'signUp',
              });
            } else {
              this.$router.push({ name: 'search' });
            }
          }
        },
        (error) => {
          console.error(this.$t('validation.failedAuthenticateGoogle') + error);
        }
      );
    },
  },
};
</script>

<style lang="scss">
.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-block {
  margin-top: calc(5dvh - env(safe-area-inset-top));
  display: flex;
  flex-direction: column;
  align-items: center;

  #logo {
    width: 112px;
    height: 112px;
  }

  .main-label {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
  }
}

.login-controls {
  .divider-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;

    .divider {
      width: 40%;
      height: 0px;
      border-bottom: 1px solid #a7a7a7;
      transform: rotate(-180deg);
    }
  }

  .apple-button,
  .google-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: white;
    border: 1px solid black;
    color: black;

    .button-icon {
      display: flex;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      margin-bottom: 2px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.sign-up-link {
  .emphasized-text {
    cursor: pointer;
    color: rgb(var(--v-theme-primary));
  }
}
</style>
