<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5958 15.2863C11.9918 15.2863 12.3324 14.9549 12.3324 14.5681V5.11196L12.2772 3.73082L12.8941 4.38456L14.2937 5.87619C14.4226 6.02351 14.6067 6.09717 14.7909 6.09717C15.1684 6.09717 15.463 5.82094 15.463 5.44343C15.463 5.25007 15.3802 5.10275 15.242 4.96463L12.1299 1.96296C11.9457 1.77881 11.7892 1.71436 11.5958 1.71436C11.4117 1.71436 11.2552 1.77881 11.0618 1.96296L7.94964 4.96463C7.81152 5.10275 7.73786 5.25007 7.73786 5.44343C7.73786 5.82094 8.01409 6.09717 8.40081 6.09717C8.57575 6.09717 8.77832 6.02351 8.90723 5.87619L10.2976 4.38456L10.9237 3.73082L10.8684 5.11196V14.5681C10.8684 14.9549 11.1999 15.2863 11.5958 15.2863ZM6.31989 22.4959H16.881C18.8054 22.4959 19.7722 21.5383 19.7722 19.6415V10.4524C19.7722 8.55559 18.8054 7.598 16.881 7.598H14.3121V9.08043H16.8534C17.7649 9.08043 18.2898 9.57764 18.2898 10.5352V19.5587C18.2898 20.5163 17.7649 21.0135 16.8534 21.0135H6.33831C5.41755 21.0135 4.91113 20.5163 4.91113 19.5587V10.5352C4.91113 9.57764 5.41755 9.08043 6.33831 9.08043H8.88881V7.598H6.31989C4.39551 7.598 3.42871 8.55559 3.42871 10.4524V19.6415C3.42871 21.5383 4.39551 22.4959 6.31989 22.4959Z"
      fill="#A7A7A7"
    />
  </svg>
</template>

<script>
export default {
  name: 'ShareSvg',
};
</script>
