<template>
  <div class="sign-up-page-wrapper d-flex flex-column px-3">
    <SmallLogoBlockVue />
    <div class="previous-step-control" @click="prevAuthStep" v-if="canGoBack">
      <img :src="arrowIcon" alt="<- Back" />
    </div>
    <component ref="signUpStep" :is="SIGN_UP_STEP_VIEW[currentStep]" @dataChange="onStepDataChange" />
    <div class="controls-block d-flex flex-column mt-3">
      <BaseButton v-if="SKIPABLE_STEPS.includes(currentStep)" class="skip-btn" @click="skip">
        {{ $t('button.skip') }}
      </BaseButton>
      <BaseButton class="primary" @click="nextAuthStep" :disabled="shouldDisableContinue">
        {{ $t('button.continue') }}</BaseButton>
    </div>
    <BaseDialog v-model="isDialogVisible" location="center" color="#2F313D" persistent class="pa-2 signup-dialog">
      <v-card class="pa-8">
        <div class="dialog-text social">{{ dialogMessage }}</div>
      </v-card>
    </BaseDialog>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';
import SignUpForm from '@/components/SignUpForm.vue';
import SmallLogoBlockVue from '@/components/SmallLogoBlock.vue';
import PhoneVerification from '@/components/PhoneVerification.vue';
import IdentityForm from '@/components/IdentityForm.vue';
import InterestsForm from '@/components/InterestsForm.vue';

export default {
  name: 'SignUpPage',
  components: {
    SignUpForm,
    BaseDialog,
    IdentityForm,
    InterestsForm,
    SmallLogoBlockVue,
    PhoneVerification,
    BaseButton,
  },
  data() {
    const SIGN_UP_STEPS = {
      SIGN_UP: 'signUp',
      VERIFICATION: 'verification',
      IDENTITY: 'identify',
      INTERESTS: 'interests',
    };
    const SIGN_UP_STEP_VIEW = {
      [SIGN_UP_STEPS.SIGN_UP]: 'SignUpForm',
      [SIGN_UP_STEPS.VERIFICATION]: 'PhoneVerification',
      [SIGN_UP_STEPS.IDENTITY]: 'IdentityForm',
      [SIGN_UP_STEPS.INTERESTS]: 'InterestsForm',
    };
    const SKIPABLE_STEPS = [SIGN_UP_STEPS.VERIFICATION];

    const setShouldOpenIdentityForm =
      this.$store.getters['UserStore/shouldOpenIdentityForm'];
    const currentStep = setShouldOpenIdentityForm
      ? SIGN_UP_STEPS.IDENTITY
      : SIGN_UP_STEPS.SIGN_UP;
    return {
      SIGN_UP_STEPS,
      SKIPABLE_STEPS,
      SIGN_UP_STEP_VIEW,
      currentStep,
      isAgreedToTermsAndPolicy: false,
      isDialogVisible: false,
      dialogMessage: '',
    };
  },
  watch: {
    currentStep() {
      if (!this.isIOS) {
        return;
      }

      if (this.currentStep === this.SIGN_UP_STEPS.IDENTITY) {
        window.WkWebView.allowsBackForwardNavigationGestures(false);
      } else {
        window.WkWebView.allowsBackForwardNavigationGestures(true);
      }
    },
  },
  methods: {
    async nextAuthStep() {
      switch (this.currentStep) {
        case this.SIGN_UP_STEPS.SIGN_UP:
          await this.signUpHandler();
          break;
        case this.SIGN_UP_STEPS.VERIFICATION:
          this.currentStep = this.SIGN_UP_STEPS.IDENTITY;
          break;
        case this.SIGN_UP_STEPS.IDENTITY:
          await this.updateUser();
          this.$store.commit('UserStore/setShouldOpenIdentityForm', false);
          break;
      }
    },
    prevAuthStep() {
      switch (this.currentStep) {
        case this.SIGN_UP_STEPS.SIGN_UP:
          this.$router.push({ name: 'login' });
          break;
        case this.SIGN_UP_STEPS.VERIFICATION:
          this.currentStep = this.SIGN_UP_STEPS.IDENTITY;
          break;
        case this.SIGN_UP_STEPS.IDENTITY:
          this.currentStep = this.SIGN_UP_STEPS.SIGN_UP;
          break;
        case this.SIGN_UP_STEPS.INTERESTS:
          this.currentStep = this.SIGN_UP_STEPS.IDENTITY;
          break;
      }
    },
    async skip() {
      switch (this.currentStep) {
        case this.SIGN_UP_STEPS.SIGN_UP:
          this.currentStep = this.SIGN_UP_STEPS.IDENTITY;
          break;
        case this.SIGN_UP_STEPS.VERIFICATION:
          this.$router.push({ name: 'search' });
          break;
        case this.SIGN_UP_STEPS.INTERESTS:
          this.$router.push({ name: 'search' });
          break;
      }
    },
    onStepDataChange(payload) {
      switch (payload.type) {
        case 'termsAndPolicy':
          this.isAgreedToTermsAndPolicy = payload.value;
          break;
      }
    },
    async signUpHandler() {
      const isSuccessful = await this.fillSignUpData();
      if (isSuccessful) {
        this.currentStep = this.SIGN_UP_STEPS.IDENTITY;
      }
    },
    async fillSignUpData() {
      await this.$refs.signUpStep.v$.$touch();

      if (this.$refs.signUpStep.v$.$error) {
        return false;
      }

      const { name, email, password, confirmPassword } =
        this.$refs.signUpStep.$data;

      const canEmailBeRegistered = await this.$store.dispatch(
        'UserStore/canEmailBeRegistered',
        email
      );

      if (!canEmailBeRegistered) {
        this.showDialog(this.$t('dialog.userWithEmailAlreadyExists'));
        setTimeout(this.clearDialog, 2000);
        return false;
      }

      this.$store.commit('UserStore/setSignUpData', {
        name,
        email,
        password,
        confirmPassword,
      });

      return true;
    },

    showDialog(message) {
      this.isDialogVisible = true;
      this.dialogMessage = message;
    },
    clearDialog() {
      this.isDialogVisible = false;
      this.dialogMessage = '';
    },
    async updateUser() {
      try {
        const newUserData = this.$refs.signUpStep.newUserData;

        const requiredFields = ['dateOfBirth', 'country', 'city'];

        const missingFields = requiredFields.filter(
          (field) => !newUserData[field]
        );

        if (missingFields.length) {
          this.showDialog(this.$t('dialog.pleaseFillAllRequiredFields'));
          setTimeout(this.clearDialog, 2000);
          return;
        }

        const isLessThan18 =
          new Date().getFullYear() - newUserData.dateOfBirth.getFullYear() < 18;

        if (isLessThan18) {
          this.showDialog(this.$t('dialog.youMustBeAtLeast'));
          setTimeout(this.clearDialog, 2000);
          return;
        }

        const signUpData = this.$store.getters['UserStore/getSignUpData'];
        const user = this.$store.getters['UserStore/getUser'];

        if (signUpData && !user) {
          await this.$store.dispatch('UserStore/signUp', signUpData);
        }

        await this.$store.dispatch('UserStore/updateUser', newUserData);

        this.$store.commit('UserStore/setSignUpData', null);

        localStorage.removeItem('shouldOpenIdentityForm');
        this.$router.push({ name: 'search' });
      } catch (error) {
        console.log(error);
      }
    },
  },

  computed: {
    arrowIcon() {
      return require('@/assets/images/arrow.svg');
    },
    canGoBack() {
      return this.currentStep !== this.SIGN_UP_STEPS.IDENTITY;
    },
    isIOS() {
      return window?.device?.platform === 'iOS';
    },
    shouldDisableContinue() {
      return (
        this.currentStep === this.SIGN_UP_STEPS.IDENTITY &&
        !this.isAgreedToTermsAndPolicy
      );
    },
  },
};
</script>

<style lang="scss">
.sign-up-page-wrapper {
  height: 100%;

  .previous-step-control {
    position: absolute;
    top: 40px;
    left: 24px;
    cursor: pointer;
  }

  .controls-block {
    height: 100%;
    justify-content: end;
    margin-bottom: calc(env(safe-area-inset-bottom) + 24px);

    button {
      color: white;
      font-weight: 700;
      font-size: 16px;
    }

    .skip-btn {
      margin-bottom: 12px;
    }
  }
}
</style>
