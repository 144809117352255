export default {
  share: ({ title, text, url, files }) => {
    if (navigator.share) {
      navigator.share({
        title,
        text,
        url,
        files,
      });
    } else if (window.plugins.socialsharing) {
      window.plugins.socialsharing.shareWithOptions({
        message: text,
        chooserTitle: title,
        url,
        files,
      });
    }
  },
};
