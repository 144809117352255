const state = () => {
  return {
    gameRulesMap: new Map()
  };
};

const mutations = {
    setGameRules(state, { gameId, gameRules }) {
        if (state.gameRulesMap[gameId]) {
            return state.gameRulesMap[gameId];
        }

        state.gameRulesMap[gameId] = gameRules;
    }
};

const getters = {
    getGameRules: state => (gameId) => {
        return state.gameRulesMap[gameId] || null;
    }
};

const actions = {
  
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
};
