<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="&#208;&#161;&#208;&#187;&#208;&#190;&#208;&#185; 3">
      <g id="Group">
        <g id="Group_2">
          <path
            id="Vector"
            d="M20.9998 33.626H13.1768V32.7993C13.1768 31.7314 13.8619 30.7981 14.8808 30.4783L18.9514 29.2006V26.0022C17.6196 25.2576 13.7273 22.9226 12.5039 20.439C11.02 17.4258 14.6519 9.71283 15.0714 8.84243L15.2968 8.37402H26.7029L26.9283 8.84243C27.3478 9.71283 30.9779 17.4258 29.4958 20.439C28.2741 22.9208 24.3818 25.2559 23.0482 26.0022V29.2006L27.1188 30.4783C28.1378 30.7981 28.8229 31.7314 28.8229 32.7993V33.626H21.0016H20.9998ZM15.6446 31.9726H26.3568L21.3931 30.4153V25.0077L21.8388 24.777C23.2143 24.0604 27.0035 21.7516 28.0102 19.7084C28.8194 18.0637 27.0751 13.0983 25.6577 10.0274H16.3402C14.9228 13.0965 13.1785 18.0637 13.9877 19.7084C14.9944 21.7516 18.7836 24.0586 20.1592 24.777L20.6048 25.0077V30.4153L15.6464 31.9726H15.6446Z"
          />
          <path
            id="Vector_2"
            d="M15.4365 19.6104H26.5612C25.5038 21.8772 23.1984 23.2929 20.7463 23.1916C18.4672 23.0989 16.4118 21.7094 15.4365 19.6104Z"
          />
        </g>
        <g id="Group_3">
          <path
            id="Vector_3"
            d="M20.9999 41.5716C9.65677 41.5716 0.428467 32.3433 0.428467 21.0001C0.428467 9.65701 9.65677 0.428711 20.9999 0.428711C32.343 0.428711 41.5713 9.65701 41.5713 21.0001C41.5713 32.3433 32.343 41.5716 20.9999 41.5716ZM20.9999 2.63267C10.8732 2.63442 2.63417 10.8735 2.63417 21.0001C2.63417 31.1268 10.8732 39.3659 20.9999 39.3659C31.1266 39.3659 39.3656 31.1268 39.3656 21.0001C39.3656 10.8735 31.1266 2.63442 20.9999 2.63442V2.63267Z"
          />
          <path
            id="Vector_4"
            d="M7.26279 7.25332L5.70312 8.81299L33.2111 36.321L34.7708 34.7613L7.26279 7.25332Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AlcoholFilterSvg',
};
</script>

<style scoped></style>
