import ServerRequest from '@/utils/ServerRequest';

const state = () => {
  return {
    selectedGame: null,
    newEventData: null,
    eventData: null,
    eventsAsParticipant: [],
    eventMembers: new Map(),
  };
};

const mutations = {
  setSelectedGame(state, game) {
    state.selectedGame = game;
  },
  setNewEventData(state, data) {
    state.newEventData = data;
  },
  setEventData(state, data) {
    state.eventData = data;
  },
  setEventsAsParticipant(state, events) {
    state.eventsAsParticipant = events;
  },
  setEventMembers(state, { eventId, members }) {
    const eventMembersMap = new Map(state.eventMembers);
    eventMembersMap.set(eventId, members);
    state.eventMembers = eventMembersMap;
  },
};

const getters = {
  getSelectedGame: (state) => state.selectedGame,
  getNewEventData: (state) => state.newEventData,
  getSelectedEvent: (state) => state.eventData,
  getEventsAsParticipant: (state) => state.eventsAsParticipant,
  isParticipating: (state) => (eventId) => {
    return state.eventsAsParticipant.some((event) => event._id === eventId);
  },
  getEventMembers: (state) => (eventId) => {
    return state.eventMembers.get(eventId) || null;
  },
};

const actions = {
  async loadEvent(context, eventId) {
    const { event } = await ServerRequest.get('events', 'getEvent', {
      eventId,
    });
    context.commit('setEventData', event);
  },
  async setSelectedEvent({ commit }, event) {
    commit('setEventData', event);
  },
  async inviteUser(context, { eventId, userId }) {
    try {
      const response = await ServerRequest.post('events', 'inviteUser', {
        eventId,
        userId,
      });
      return response.isInvited;
    } catch (error) {
      console.log(error);
    }
  },
  async reportEvent({ rootGetters }, { eventId, reason }) {
    try {
      const st = rootGetters['UserStore/getUser']?.sessionToken || '';
      const response = await ServerRequest.post('events', 'reportEvent', {
        eventId,
        reason,
        st,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async finishEvent({ commit }, eventId) {
    try {
      const response = await ServerRequest.post('events', 'finishEvent', {
        eventId,
      });

      const event = response.event;
      commit('setEventData', event);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getEventsAsParticipant({ commit }, eventId) {
    const response = await ServerRequest.get(
      'events',
      'getEventAsParticipant',
      { eventId }
    );
    commit('setEventData', response);
  },
  async loadMembers({ commit }, eventId) {
    const { members } = await ServerRequest.get('events', 'getMembers', {
      eventId,
    });
    commit('setEventMembers', { eventId, members });
    members.forEach((member) => {
      commit(
        'UserStore/setUserById',
        { userId: member._id, user: member },
        { root: true }
      );
    });
  },
  async getEventChat({ commit, rootGetters }, eventId) {
    const st = rootGetters['UserStore/getUser']?.sessionToken || '';
    const { messages } = await ServerRequest.get('events', 'getEventChat', {
      st,
      eventId,
    });
    return messages;
  },
  async acceptParticipant(context, { eventId, userId }) {
    try {
      const response = await ServerRequest.post('events', 'acceptParticipant', {
        eventId,
        userId,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async rejectParticipant(context, { eventId, userId }) {
    try {
      const response = await ServerRequest.post('events', 'rejectParticipant', {
        eventId,
        userId,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async joinEvent(context, { eventId, userId }) {
    try {
      const response = await ServerRequest.post('events', 'requestToJoin', {
        eventId,
        userId,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async checkParticipationStatus(context, { userId, eventId }) {
    try {
      const { requestStatus } = await ServerRequest.get(
        'events',
        'checkParticipationStatus',
        { userId, eventId }
      );
      return requestStatus;
    } catch (error) {
      console.log(error);
    }
  },
  async rescheduleEvent(
    { rootGetters, commit },
    { eventId, date, startsAt, endsAt }
  ) {
    try {
      const st = rootGetters['UserStore/getUser']?.sessionToken;
      const response = await ServerRequest.post('events', 'rescheduleEvent', {
        st,
        eventId,
        date,
        startsAt,
        endsAt,
      });

      if (response.isRescheduled) {
        commit('setEventData', response.updatedEvent);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async subscribeToSpotNotification({ rootGetters }, { eventId, userId }) {
    try {
      const st = rootGetters['UserStore/getUser']?.sessionToken;
      const response = await ServerRequest.post(
        'events',
        'subscribeToSpotNotification',
        {
          st,
          eventId,
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async cancelEvent({ rootGetters, getters, commit }, eventId) {
    try {
      const st = rootGetters['UserStore/getUser']?.sessionToken;
      const response = await ServerRequest.post('events', 'cancelEvent', {
        st,
        eventId,
      });

      window.console.log(response);
      if (response.isCanceled) {
        const event = getters.getSelectedEvent;
        event.isCanceled = true;

        commit('setEventData', event);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async leaveEvent({ rootGetters }, eventId) {
    try {
      const st = rootGetters['UserStore/getUser']?.sessionToken;
      const response = await ServerRequest.post('events', 'leaveEvent', {
        st,
        eventId,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async removeParticipant({ rootGetters }, { eventId, userId }) {
    try {
      const st = rootGetters['UserStore/getUser']?.sessionToken;
      const response = await ServerRequest.post('events', 'removeParticipant', {
        st,
        eventId,
        userId,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
};
