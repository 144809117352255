<template>
  <div class="event-chat">
    <div class="loader" v-if="!isLoaded">
      <v-skeleton-loader
        v-for="i in 10"
        :key="i"
        color="background"
        :height="150"
        type="list-item-avatar-three-line"
      />
    </div>
    <div v-else-if="messages.length" class="messages-view px-3" ref="chat">
      <ChatMessage
        v-for="(message, index) in messages"
        :key="index"
        :message="message"
        :class="message.sender === 'me' ? 'message-me' : 'message-other'"
      >
      </ChatMessage>
    </div>

    <div v-else class="empty-messages">
      <v-icon>mdi-chat-outline</v-icon>
      <span>No messages yet</span>
    </div>

    <div class="message-input-wrapper px-3">
      <v-textarea
        v-model="message"
        hide-details
        rows="1"
        label="Message"
        class="message-input"
        variant="outlined"
        density="compact"
        ref="messageInput"
        :append-inner-icon="message.length ? 'mdi-send-variant-outline' : ''"
        auto-grow
        @click:appendInner="sendMessage"
      />
    </div>
  </div>
</template>

<script>
import ChatMessage from '@/components/ChatMessage.vue';
import SocketUtils from '@/utils/SocketUtils';

export default {
  name: 'EventChat',
  data() {
    return {
      isLoaded: false,
      messages: [],
      message: '',
      rowsAmount: 1,
    };
  },
  async mounted() {
    this.messages = await this.$store.dispatch(
      'EventStore/getEventChat',
      this.eventId
    );
    this.isLoaded = true;
    this.$nextTick(() => {
      this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
    });
    SocketUtils.emit('join', this.eventId);
    SocketUtils.on('chat-message', (data) => {
      this.messages.push(data);
      this.$nextTick(() => {
        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
      });
    });
  },
  beforeUnmount() {
    SocketUtils.emit('leave', this.eventId);
    SocketUtils.off('chat-message');
  },
  components: {
    ChatMessage,
  },
  watch: {
    isKeyboardVisible() {
      this.$nextTick(() => {
        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
      });
    },
  },
  computed: {
    userId() {
      return this.$store.getters['UserStore/getUserId'];
    },
    eventId() {
      return this.$route.params.id;
    },
    isMobile() {
      return !!window.cordova;
    },
    isKeyboardVisible() {
      return this.$store.getters['SettingsStore/isKeyboardVisible'];
    },
  },
  methods: {
    async sendMessage(e) {
      e.preventDefault();
      e.stopPropagation();

      if (this.message) {
        SocketUtils.emit('chat-message', {
          message: this.message,
          senderId: this.userId,
          eventId: this.eventId,
          timestamp: new Date(),
        });

        this.message = '';
        this.$refs.messageInput.focus();
      }
    },
  },
};
</script>

<style lang="scss">
.event-chat {
  display: flex;
  flex-direction: column;
  height: 100%;

  .messages-view {
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 8px;
    border-radius: 8px;
    // margin: 8px;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
  }

  .v-field__append-inner {
    i {
      color: rgb(var(--v-theme-primary));
      transform: rotate(-90deg);
    }
  }

  .empty-messages {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    height: 100%;
    color: #ccc;

    v-icon {
      font-size: 48px;
    }
  }

  .message-input-wrapper {
    padding: 6px 0 12px 0;
    .v-field__input {
      padding: 8px;
    }
  }

  .empty-messages {
    align-items: center;
    justify-content: center;
  }
}
</style>
