<template>
  <div class="create-event-form">
    <PhotoBlock @photo-uploaded="onPhotoUpload" :canUpload="true" :image="image" />
    <div class="game-block mt-3">
      <span class="label">{{ $t('label.game') }}*</span>
      <div class="game-item-wrapper d-flex flex-column" v-if="selectedGame">
        <GameListItem :game-id="selectedGame.id" class="mt-2" :hide-actions="true" />
        <div class="change-game mt-6 mb-2 py-2" @click="selectGame">
          {{ $t('button.changeGame') }}
        </div>
      </div>
      <div @click="selectGame" class="select-game my-2 py-6" v-else>{{ $t('button.select') }}</div>
      <small v-if="v$.selectedGame.$error" class="error">{{
        v$ && v$.selectedGame.$errors[0].$message
      }}</small>
    </div>
    <div class="name-block my-3">
      <BaseInput v-model="name" placeholder="placeholder.name" inputLabel="label.eventName" />
      <small v-if="v$.name.$error" class="error">{{
        v$ && v$.name.$errors[0].$message
      }}</small>
    </div>
    <div class="description-block my-3">
      <BaseInput v-model="description" placeholder="placeholder.description" inputLabel="label.text" />
    </div>
    <div class="date-block">
      <span class="label">{{ $t('label.dateAndTime') }}</span>
      <BaseDatePicker v-model="date" :min="yesterday" />
      <div class="time-pickers mt-4 mb-2 d-flex justify-space-between">
        <div class="time-picker-wrapper">
          <!-- <v-icon icon="mdi-clock-outline me-2" /> -->
          <span>{{ $t('label.start') }}</span>
          <input class="ms-2" type="time" name="start" id="startTime" ref="startTime" :value="startsAt"
            @input="startsAt = $event.target.value" step="60" pattern="[0-9]{2}:[0-9]{2}" />
        </div>

        <div class="time-picker-wrapper">
          <!-- <v-icon icon="mdi-clock-outline me-2" v-if="" /> -->
          <span>{{ $t('label.end') }}</span>
          <input class="ms-2" type="time" name="end" ref="endTime" :value="endsAt" id="endTime" step="60"
            @input="endsAt = $event.target.value" pattern="[0-9]{2}:[0-9]{2}" />
        </div>
      </div>
    </div>
    <div class="location-block">
      <BaseInput v-model="locationText" id="event-location" placeholder="placeholder.location"
        inputLabel="label.location*" @input="debouncedLocationHandler($event, googleAutocompleteOptions)"
        prependInner="mdi-map-marker-outline" />
      <small v-if="v$.locationText.$error" class="error">{{
        v$ && v$.locationText.$errors[0].$message
      }}</small>
      <div class="location-predictions" v-if="locationPredictions.length">
        <div class="prediction py-1 px-2" v-for="prediction in locationPredictions" :key="prediction.place_id"
          @click="selectLocation(prediction)">
          {{ prediction.description }}
        </div>
      </div>
    </div>
    <div class="players-block my-3 d-flex flex-column">
      <span class="label">{{ $t('label.numberOfPlayers') }}</span>
      <div class="range-wrapper d-flex justify-space-between">
        <div class="group d-flex">
          <BaseInput v-model="minPlayers" maxlength="2" class="me-2" />
          <span class="label">{{ $t('label.min') }}</span>
        </div>
        <div class="group d-flex">
          <BaseInput v-model="maxPlayers" maxlength="2" class="me-2" />
          <span class="label">{{ $t('label.max') }}</span>
        </div>
      </div>
    </div>
    <div class="filters-block pb-1">
      <span class="label">{{ $t('label.filters') }}</span>
      <div class="event-filters d-flex mt-1 mb-3">
        <SmokingFilter class="me-3" :class="{ 'selected-filter': noSmoking }" @click="toggleFilter('noSmoking')" />
        <AgeFilter class="me-3" :class="{ 'selected-filter': noKids }" @click="toggleFilter('noKids')" />
        <AlcoholFilter class="me-3" :class="{ 'selected-filter': noAlcohol }" @click="toggleFilter('noAlcohol')" />
        <PetsFilter :class="{ 'selected-filter': noPets }" @click="toggleFilter('noPets')" />
      </div>
    </div>
    <div class="settings-block">
      <div class="settings-list mt-1">
        <div class="settings-item">
          <span class="my-auto">{{ $t('label.publicPlace') }}</span>
          <BaseSwitch v-model="publicPlace" />
        </div>
        <div class="settings-item">
          <span class="my-auto">{{ $t('label.youNeedToBuySomething') }}</span>
          <BaseSwitch v-model="buySomething" />
        </div>
        <div class="settings-item">
          <span class="my-auto">{{ $t('label.creatorParticipatesAsAPlayer') }}</span>
          <BaseSwitch v-model="creatorParticipates" />
        </div>
        <!-- <div class="settings-item">
          <span class="my-auto">{{ $t('label.paidEvent') }}</span>
          <BaseSwitch v-model="paidEvent" />
        </div>
        <div class="paid-event-price" v-if="paidEvent">
          <BaseInput v-model="price" maxlength="5" placeholder="placeholder.priceForParticipation"
            inputLabel="label.priceInLocalCurrency" />
          <small v-if="v$.price.$error" class="error">{{
            v$ && v$.price.$errors[0].$message
          }}</small>
        </div> -->
      </div>
    </div>
    <div class="controls-block mt-2 pb-4">
      <BaseButton class="primary my-2" @click="createEvent" :disabled="isCreatingEvent"
        :class="{ disabled: isCreatingEvent }">{{ $t('button.create') }}</BaseButton>
      <BaseButton class="cancel" @click="resetEvent">{{ $t('button.reset') }}</BaseButton>
    </div>

    <BaseDialog v-model="isDialogVisible" location="center" color="#2F313D" persistent class="pa-2 create-event-dialog">
      <v-card class="pa-8">
        <div class="dialog-text social">{{ dialogMessage }}</div>
      </v-card>
    </BaseDialog>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseSwitch from '@/components/base/BaseSwitch.vue';
import GameListItem from '@/components/GameListItem.vue';
import BaseDatePicker from '@/components/base/BaseDatePicker.vue';
import SmokingFilter from '@/components/icons/SmokingFilter.vue';
import AgeFilter from '@/components/icons/AgeFilter.vue';
import AlcoholFilter from '@/components/icons/AlcoholFilter.vue';
import PetsFilter from '@/components/icons/PetsFilter.vue';
import ServerRequest from '@/utils/ServerRequest';
import Event from '@/classes/Event';
import PhotoBlock from '@/components/PhotoBlock.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';
import locationInputMixin from '@/mixins/locationInputMixin';
import jsConfetti from '@/utils/ConfettiUtils';
import { required, helpers, numeric } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  name: 'CreateEventForm',
  components: {
    BaseInput,
    BaseButton,
    BaseSwitch,
    GameListItem,
    BaseDatePicker,
    SmokingFilter,
    AgeFilter,
    AlcoholFilter,
    PetsFilter,
    PhotoBlock,
    BaseDialog,
  },
  mixins: [locationInputMixin],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      name: { required: helpers.withMessage(this.$t('validation.nameIsRequired'), required) },
      selectedGame: {
        required: helpers.withMessage(this.$t('validation.pleaseSelectGame'), required),
      },
      locationText: {
        required: helpers.withMessage(this.$t('validation.locationIsRequired'), required),
      },
      price: {
        numeric: helpers.withMessage(this.$t('validation.priceShouldBeNumber'), numeric),
      },
    };
  },
  data() {
    const existingEvent = this.$store.getters['EventStore/getNewEventData'];
    let event = null;

    if (existingEvent) {
      existingEvent.startsAt = new Date(existingEvent.startsAt)
        .toTimeString()
        .substring(0, 5);
      existingEvent.endsAt = new Date(existingEvent.endsAt)
        .toTimeString()
        .substring(0, 5);
      event = { ...existingEvent };
      this.$store.commit('EventStore/setNewEventData', null);
    } else {
      event = new Event();
    }

    return {
      isCreatingEvent: false,
      yesterday: new Date(new Date().setDate(new Date().getDate() - 1)),
      isDialogVisible: false,
      dialogMessage: '',
      ...event,
    };
  },
  methods: {
    onPhotoUpload(image) {
      this.image = image;
    },
    clearEventFields() {
      const event = new Event();
      this.name = event.name;
      this.description = event.description;
      this.date = event.date;
      this.startsAt = event.startsAt;
      this.endsAt = event.endsAt;
      this.locationText = event.locationText;
      this.place_id = event.place_id;
      this.minPlayers = event.minPlayers;
      this.maxPlayers = event.maxPlayers;
      this.noSmoking = event.noSmoking;
      this.noKids = event.noKids;
      this.noAlcohol = event.noAlcohol;
      this.noPets = event.noPets;
      this.inviteOnly = event.inviteOnly;
      this.publicPlace = event.publicPlace;
      this.buySomething = event.buySomething;
      this.paidEvent = false;
      this.price = 0;
      this.creatorParticipates = event.creatorParticipates;
      this.image = event.image;
    },
    gatherEventInfo() {
      const event = new Event();
      event.name = this.name;
      event.gameName = this.selectedGame?.name;
      event.description = this.description;
      event.date = this.date;
      const startsAt = new Date(this.date);
      startsAt.setHours(this.startsAt.split(':')[0]);
      startsAt.setMinutes(this.startsAt.split(':')[1]);
      event.startsAt = startsAt.toISOString();
      const endsAt = new Date(this.date);
      endsAt.setHours(this.endsAt.split(':')[0]);
      endsAt.setMinutes(this.endsAt.split(':')[1]);
      event.endsAt = endsAt.toISOString();
      event.locationText = this.locationText;
      event.place_id = this.place_id;
      event.playTimeRange = [
        this.selectedGame?.minPlayTime,
        this.selectedGame?.maxPlayTime,
      ];
      event.minPlayers = this.minPlayers;
      event.maxPlayers = this.maxPlayers;

      // rules | filters
      event.noSmoking = this.noSmoking;
      event.noKids = this.noKids;
      event.noAlcohol = this.noAlcohol;
      event.noPets = this.noPets;
      // settings

      event.inviteOnly = this.inviteOnly;
      event.publicPlace = this.publicPlace;
      event.buySomething = this.buySomething;
      event.paidEvent = false;
      event.price = 0;

      event.gameId = this.selectedGame?.id;
      event.creator = this.currentUserId;
      event.image = this.image;
      event.creatorParticipates = this.creatorParticipates;
      if (this.creatorParticipates) {
        event.players = [this.currentUserId];
      }
      return event;
    },
    resetEvent() {
      this.$store.commit('EventStore/setNewEventData', null);
      this.$store.commit('EventStore/setSelectedGame', null);
      this.clearEventFields();
      const pageContentElement =
        document.getElementsByClassName('page-content')[0];
      if (pageContentElement) {
        pageContentElement.scrollTop = 0;
      }
    },
    showDialog(message) {
      this.isDialogVisible = true;
      this.dialogMessage = message;

      setTimeout(() => {
        this.isDialogVisible = false;
      }, 1000);
    },
    selectGame() {
      this.$store.commit('EventStore/setNewEventData', this.gatherEventInfo());
      this.$router.push({ name: 'selectGame' });
    },
    async createEvent() {
      if (this.isCreatingEvent) {
        return;
      }

      this.v$.$touch();

      try {
        const event = this.gatherEventInfo();

        if (
          !event.gameId ||
          !event.name ||
          !event.date ||
          !event.locationText
        ) {
          this.showDialog(this.$t('dialog.fillAllNecessaryFields'));
          return;
        }

        const isTimeRangeValid =
          new Date(event.startsAt) < new Date(event.endsAt);

        if (!isTimeRangeValid) {
          this.showDialog(this.$t('dialog.endTimeShouldBeLater'));
          return;
        }

        const isPlayersRangeValid =
          event.minPlayers && event.maxPlayers &&
          parseInt(event.minPlayers) <= parseInt(event.maxPlayers) &&
          parseInt(event.minPlayers) > 1 && parseInt(event.maxPlayers) > 1;

        if (!isPlayersRangeValid) {
          this.showDialog(this.$t('dialog.maxPlayersShouldBeMore'));
          return;
        }

        // if (this.paidEvent && this.v$.price.$error) {
        //   this.showDialog(this.$t('dialog.pleaseEnterValidPrice'));
        //   return;
        // }

        this.isCreatingEvent = true;

        const result = await ServerRequest.post('events', 'createEvent', event);

        if (result) {
          this.$store.commit('EventStore/setNewEventData', null);
          this.$store.commit('EventStore/setSelectedGame', null);
          jsConfetti.addConfetti({
            emojis: ['🌈', '⚡️', '💥', '✨', '💫', '🌸'],
          });
          this.$store.dispatch('SearchStore/search', {
            category: 'events',
            query: '',
          });
          setTimeout(() => {
            this.$router.push({ name: 'search' });
          }, 1000);
        }
      } catch (error) {
        this.showDialog(this.$t('dialog.somethingWentWrong'));
        this.isCreatingEvent = false;
        window.console.log(error);
      }
    },
    toggleFilter(filterName) {
      this[filterName] = !this[filterName];
    },
  },
  computed: {
    selectedGame() {
      return this.$store.getters['EventStore/getSelectedGame'];
    },
    currentUserId() {
      return this.$store.getters['UserStore/getUser']?._id;
    },
    googleAutocompleteOptions() {
      const userCountry = this.$store.getters['UserStore/getCountry']
      const countriesList = this.$store.getters['SearchStore/getCountriesList'];
      const countryIso2 = countriesList.find(
        (country) => country.name === userCountry || country.native === userCountry
      )?.iso2 || '';

      if (!countryIso2) {
        return {};
      }

      return {
        componentRestrictions: { country: countryIso2 },
      };
    },
  },
};
</script>

<style lang="scss">
.create-event-dialog {
  text-align: center;
}

.create-event-form {
  .event-filters {
    svg {
      fill: grey;

      &.selected-filter {
        fill: white;
      }
    }
  }

  .game-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .game-list-item,
  .change-game {
    width: 100%;
  }

  .location-predictions {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    overflow-y: auto;
  }

  .settings-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .change-game,
  .select-game {
    color: rgb(var(--v-theme-primary));
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;

    text-align: center;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  .range-wrapper {
    .group {
      align-items: center;
    }

    .v-field__input {
      width: 80px;
      min-height: 44px;
      height: 44px;
      padding: 8px 10px;
    }

    .v-field {
      border-radius: 8px;
    }
  }

  .time-picker-wrapper {
    display: flex;
    align-items: center;

    input {
      border: 1px solid rgba(255, 255, 255, 0.4);
      max-width: 100px;
      width: fit-content;
      border-radius: 8px;
      padding: 0 6px 0 4px;
      color: white;
    }

    input[type='time']::-webkit-calendar-picker-indicator {
      background: none;
      display: none;
    }
  }

  .filters-block {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
}
</style>
