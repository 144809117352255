import ServerRequest from "./ServerRequest";
class BoardGamesApi {
  constructor(apiBaseUrl) {
    this.apiBaseUrl = apiBaseUrl;
  }

  getApiUrl() {
    return this.apiBaseUrl;
  }

  async _fetchWrapper(url, type = 'json') {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('API not available');
      }

      let data = null;
      switch (type) {
        case 'json':
          data = await response.json();
          break;
        case 'text':
          data = await response.text();
          break;
        case 'xml':
          data = await response.text();
          data = new DOMParser().parseFromString(data, 'text/xml');
          break;
        default:
          data = await response.json();
      }

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  search() {
    throw new Error('Not implemented');
  }

  getHotItems() {
    throw new Error('Not implemented');
  }

  async checkIfAvailable() {
    this._fetchWrapper;
  }
}

class BoardGeekGameItem {
  constructor(xmlItem) {
    this.id = xmlItem.getAttribute('id');
    this.name = xmlItem.querySelector('name')?.getAttribute('value') || '???';
    this.yearPublished =
      xmlItem.querySelector('yearpublished')?.getAttribute('value') || 'n/a';
    this.thumbnail =
      xmlItem.querySelector('thumbnail')?.textContent ||
      xmlItem.querySelector('thumbnail')?.getAttribute('value') ||
      null;
    null;
  }
}

// class DetailedGameItem extends BoardGeekGameItem {
//   constructor(xmlItem) {
//     super(xmlItem);
//     this.description = xmlItem.querySelector('description')?.textContent;
//     this.image = xmlItem.querySelector('image')?.textContent;
//     this.minPlayers = xmlItem
//       .querySelector('minplayers')
//       ?.getAttribute('value');
//     this.maxPlayers = xmlItem
//       .querySelector('maxplayers')
//       ?.getAttribute('value');
//     this.minPlayTime = xmlItem
//       .querySelector('minplaytime')
//       ?.getAttribute('value');
//     this.maxPlayTime = xmlItem
//       .querySelector('maxplaytime')
//       ?.getAttribute('value');
//     this.minAge = xmlItem.querySelector('minage')?.getAttribute('value');
//   }
// }

class BoardGamesGeekApi extends BoardGamesApi {
  constructor() {
    super('https://www.boardgamegeek.com/xmlapi2/');
  }

  _parseGameItems(xmlItems) {
    let items = xmlItems.querySelectorAll('item');
    const gameItems = Array.from(items).map(
      (item) => new BoardGeekGameItem(item)
    );
    return gameItems;
  }

  _parseThumbnails(xmlItems) {
    let thumbnails = xmlItems?.querySelectorAll('item thumbnail');
    thumbnails = Array.from(thumbnails).map(
      (thumbnail) => thumbnail?.textContent || null
    );
    return thumbnails;
  }

  async search(searchTerm) {
    const gamesByQuery = await this._fetchWrapper(
      `${this.apiBaseUrl}search?type=boardgame&query=${searchTerm}`,
      'xml'
    );

    let foundGames = this._parseGameItems(gamesByQuery);
    let gamesIds = foundGames.map((game) => game.id);

    if (gamesIds.length > 50) {
      gamesIds = gamesIds.slice(0, 50);
    }

    const games = await this.getGamesInfo(gamesIds);

    return games;
  }

  async getHotItems() {
    const response = await this._fetchWrapper(
      `${this.apiBaseUrl}hot?type=boardgame`,
      'xml'
    );

    return this._parseGameItems(response).map((game) => game.id)
  }

  async getGamesInfo(gameIds) {
    try {
      const response = await ServerRequest.get('games', 'getGamesInfo', { gameIds });
      return response.gamesInfo;
    } catch (error) {
      window.console.log('Error getting game info');
      window.console.log(error);
    }
  }
}

const API = new BoardGamesGeekApi();

export default API;
