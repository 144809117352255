<template>
  <div class="user-list-item d-flex">
    <div class="user-avatar d-flex pt-1 me-6" @click="openProfile">
      <img v-if="item.avatar" :src="item.avatar" alt="avatar" />
      <div class="avatar-placeholder" v-else>
        <v-icon>mdi-account-circle</v-icon>
      </div>
    </div>
    <div class="user-info d-flex flex-column" @click="openProfile">
      <div class="name">{{ item.name }}</div>
      <!-- <div v-if="age" class="age">
        <span>{{ age + ' ' + $t('label.yearsOld') }} </span>
      </div> -->
      <!-- <div v-if="item.location" class="location">
        <LocationSvg />
        <span>{{ item.location }}</span>
      </div> -->
      <!-- <div v-if="item.rating" class="rating">
        <span>{{ item.rating }}</span>
        <StarSvg />
      </div> -->
    </div>
    <div class="controls d-flex" v-if="!hideControls && !item.isDeleted && !isBlockedByMe">
      <BottomMenu :menuItems="menuItems" />
      <div class="request-controls mt-2" v-if="isRequestToJoin">
        <div class="icon-control-wrapper reject me-3" @click="rejectParticipant">
          <CrossSvg />
        </div>
        <div class="icon-control-wrapper accept" @click="acceptParticipant">
          <CheckSvg />
        </div>
      </div>
      <BaseButton v-if="isInviteView && !isInvited" :width="100" class="primary" @click="inviteUser">
        Invite
      </BaseButton>
      <BaseButton v-else-if="isInviteView && isInvited" :width="100" class="primary" :disabled="true">Invited
      </BaseButton>
      <BaseButton v-else-if="item._id !== currentUserId && !isRequestToJoin" :max-width="110"
        :class="{ primary: !isFollowing }" @click="changeFollowingStatus">
        <span v-if="isFollowing">{{ $t('button.following') }}</span>
        <span v-else>{{ $t('button.follow') }}</span>
      </BaseButton>
      <div v-else class="empty-controls"></div>
    </div>
    <div class="blocked-label" v-else-if="isBlockedByMe && !hideBlockedLabel">{{ $t('label.blocked') }}</div>
    <ReportDialog v-model="isReportDialogVisible" :name="item.name" :user-id="item._id" />
    <YesNoDialog v-model="isBlockDialogVisible" :dialog-text="$t('dialog.sureToBlock', { name: item.name })"
      @confirm="blockUser" @cancel="onCancelBlock" :is-cancel-red="false" :is-confirm-red="true" />
  </div>
</template>

<script>
// import StarSvg from '@/components/icons/StarSvg.vue';
import BottomMenu from '@/components/BottomMenu.vue';
import CheckSvg from '@/components/icons/CheckSvg.vue';
import CrossSvg from '@/components/icons/CrossSvg.vue';
import BaseButton from '@/components/base/BaseButton.vue';
// import LocationSvg from '@/components/icons/LocationSvg.vue';
import SharingUtils from '@/utils/SharingUtils';
import ReportDialog from '@/components/views/ReportDialog.vue';
import YesNoDialog from '@/components/views/YesNoDialog.vue';
import { landing } from '@/constants/urls';

export default {
  name: 'UserListItem',
  components: {
    CheckSvg,
    CrossSvg,
    ReportDialog,
    BaseButton,
    BottomMenu,
    YesNoDialog,
    // LocationSvg,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isRequestToJoin: {
      type: Boolean,
      default: false,
    },
    isInviteView: {
      type: Boolean,
      default: false,
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
    disableRedirect: {
      type: Boolean,
      default: false,
    },
    hideBlockedLabel: {
      type: Boolean,
      default: false,
    },
    removeFromEventVisible: {
      type: Boolean,
      default: false,
    }
  },
  mounted() { },
  data() {
    return {
      isInvited: false,
      isButtonLoading: false,
      isBlockDialogVisible: false,
      isReportDialogVisible: false,
    };
  },
  computed: {
    isFollowing() {
      return this.$store.getters['UserStore/isFollowing'](this.item._id);
    },
    isBlockedByMe() {
      return this.$store.getters['UserStore/isBlockedByMe'](this.item._id);
    },
    currentUserId() {
      return this.$store.getters['UserStore/getUserId'];
    },
    isEventPage() {
      return this.$route.name === 'event';
    },
    age() {
      return this.item.dateOfBirth
        ? new Date().getFullYear() -
        new Date(this.item.dateOfBirth).getFullYear()
        : 18;
    },
    menuItems() {
      const menuItems = [
        {
          title: this.$t('menuItem.shareAccount', this.item.name),
          icon: 'mdi-share-variant',
          color: '#FFFFFF',
          isVisible: true,
          handler: () => {
            const url = `${landing}/profileId/${this.item._id}`;
            SharingUtils.share({
              url,
              title: 'Share account',
              text: this.item.name,
            });
          },
        },
        {
          title: this.$t('menuItem.reportProfile', this.item.name),
          icon: 'mdi-flag-outline',
          isVisible: this.item._id !== this.currentUserId,
          color: '#D63B3B',
          handler: () => {
            this.showReportDialog();
          },
        },
        {
          title: this.$t('menuItem.removeParticipant', this.item.name),
          icon: 'mdi-account-remove',
          color: '#D63B3B',
          isVisible: this.isEventPage && this.removeFromEventVisible && this.item._id !== this.currentUserId,
          handler: () => {
            this.$emit('removeParticipant', { userId: this.item._id, name: this.item.name });
          },
        },
        {
          title: this.$t('menuItem.block', this.item.name),
          icon: 'mdi-block-helper',
          color: '#D63B3B',
          isVisible: this.item._id !== this.currentUserId,
          handler: () => {
            this.showBlockDialog()
          },
        },
      ];

      return menuItems.filter((item) => item.isVisible);
    },
  },
  methods: {
    async acceptParticipant() {
      this.$emit('acceptParticipant', this.item._id);
    },
    async rejectParticipant() {
      this.$emit('rejectParticipant', this.item._id);
    },
    async inviteUser() {
      this.$emit('inviteUser', this.item._id);
      this.isInvited = true;
    },
    showReportDialog() {
      this.isReportDialogVisible = true;
    },
    showBlockDialog() {
      this.isBlockDialogVisible = true;
    },
    async blockUser() {
      this.isBlockDialogVisible = false;
      await this.$store.dispatch('UserStore/blockUser', this.item._id);
      await this.$store.dispatch('UserStore/refreshUser')
      this.$emit('blockedUser');
    },
    onCancelBlock() {
      this.isBlockDialogVisible = false;
    },
    async changeFollowingStatus() {
      if (this.isButtonLoading || !this.item._id) {
        return;
      }

      try {
        this.isButtonLoading = true;
        await this.$store.dispatch('UserStore/changeFollowingStatus', {
          userId: this.item._id,
        });
        this.isButtonLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    openProfile() {
      if (this.disableRedirect) {
        return;
      }

      this.$router.push({ name: 'profileId', params: { id: this.item._id } });
    },
  },
};
</script>

<style lang="scss">
.user-list-item {
  border-bottom: 1px solid rgb(63, 65, 71);
  padding-bottom: 16px;

  &:last-of-type {
    border-bottom: none;
  }

  .rating {
    svg {
      fill: #cccccc;
    }
  }

  .user-avatar {
    img {
      height: 72px;
      width: 72px;
      border-radius: 15%;
      object-fit: cover;
    }

    .avatar-placeholder i {
      font-size: 72px;
      opacity: 0.5;

      & {
        background-color: rgba(0, 0, 0, 0.25);
        border-radius: 15%;
      }
    }
  }

  .blocked-label {
    color: rgb(var(--v-theme-error));
  }

  .empty-controls {
    width: 100px;
  }

  .request-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-control-wrapper {
      display: flex;
      cursor: pointer;
      padding: 8px;
      border-radius: 12px;

      &.accept {
        border: 1px solid #6fcf97;

        svg {
          fill: #6fcf97;
        }
      }

      &.reject {
        border: 1px solid #e57373;

        svg {
          fill: #e57373;
        }
      }
    }
  }

  .user-info {
    flex-grow: 3;

    .name,
    .age,
    .location,
    .rating {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }

    .name {
      font-weight: 500;
    }

    .age,
    .location,
    .rating {
      padding-top: 2px;
      line-height: 20px;
      color: #d0d0d0;
    }
  }

  .controls {
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .v-icon {
      transform: rotate(90deg);
      color: rgb(var(--v-theme-grey-icon));
    }

    .v-btn {
      font-weight: 400;
      margin-top: 12px;
    }
  }
}
</style>
