<template>
  <div class="edit-profile">
    <div class="edit-profile-form">
      <PhotoBlock @photo-uploaded="onPhotoUpload" :image="newUserData.avatar" :can-upload="true"
        @uploadStarted="onUploadStart" />
      <div class="element-wrapper pt-4">
        <BaseInput v-model="newUserData.name" placeholder="placeholder.name" inputLabel="label.yourName*" />
      </div>

      <div class="element-wrapper">
        <BaseDatePicker v-model="newUserData.dateOfBirth" inputLabel="label.dateOfBirth"
          append-inner-icon="mdi-calendar" />
      </div>

      <div class="element-wrapper">
        <BaseSelect outerLabel="label.identify" :items="identities" v-model="newUserData.identity" />
      </div>

      <div class="element-wrapper">
        <BaseInput inputLabel="label.about" v-model="newUserData.about" placeholder="placeholder.description" />
      </div>

      <div class="element-wrapper">
        <BaseAutocompleteSelect outerLabel="label.countryRegion" v-model="newUserData.country" :items="countriesList" />
      </div>

      <div class="element-wrapper">
        <BaseInput inputLabel="label.city" v-model="newUserData.city" placeholder="placeholder.city"
          @input="debouncedLocationHandler($event, googleAutocompleteOptions)" />
        <div class="location-predictions" v-if="locationPredictions.length">
          <div class="prediction py-1 px-2" v-for="prediction in locationPredictions" :key="prediction.place_id"
            @click="selectLocation(prediction, true)">
            {{ prediction.description }}
          </div>
        </div>
      </div>

      <div class="element-wrapper">
        <BaseInput inputLabel="label.language" v-model="newUserData.language" placeholder="placeholder.language" />
      </div>

    </div>
    <div class="edit-profile-buttons pb-4">
      <BaseButton class="primary" @click="save" :disabled="!isUploadFinished">{{
        $t('button.save')
      }}</BaseButton>
    </div>
    <BaseDialog v-model="isDialogVisible" location="center" color="#2F313D" persistent class="pa-2 dialog-edit-profile">
      <v-card class="pa-8">
        <div class="dialog-text social">{{ dialogMessage }}</div>
      </v-card>
    </BaseDialog>
  </div>
</template>

<script>
import PhotoBlock from '@/components/PhotoBlock.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseDatePicker from '@/components/base/BaseDatePicker.vue';
import BaseAutocompleteSelect from '@/components/base/BaseAutocompleteSelect.vue';

import locationInputMixin from '@/mixins/locationInputMixin';

import jsConfetti from '@/utils/ConfettiUtils';

export default {
  name: 'EditProfilePage',
  components: {
    BaseInput,
    BaseButton,
    BaseSelect,
    BaseDialog,
    PhotoBlock,
    BaseDatePicker,
    BaseAutocompleteSelect,
  },
  mixins: [locationInputMixin],
  data() {
    const user = this.$store.getters['UserStore/getUser'];
    const countries = this.$store.getters['SearchStore/getCountriesList'];
    const countriesList = this.$store.getters['SearchStore/getCountriesList'].map((c) => c.native);
    const nativeCountriesList = Object.values(this.$store.getters['SearchStore/getCountriesList']).map(
      (c) => c.native
    );
    const isAlreadyNativeCountryName = !!nativeCountriesList.find((c) => c === user.country);
    const nativeCountryName = countries.find((c) => c.native === user.country || c.name === user.country)?.native;

    const country = isAlreadyNativeCountryName ? user.country : nativeCountryName;

    return {
      countriesList,
      newUserData: {
        name: user.name || '',
        dateOfBirth: new Date(user.dateOfBirth),
        avatar: user.avatar || null,
        identity: user.identity || '',
        about: user.about || '',
        country: country || '',
        city: user.city || '',
        language: user.language || '',
        place_id: '',
      },
      isDialogVisible: false,
      dialogMessage: '',
      isUploadFinished: true,
      identities: [
        this.$t('identities.male'),
        this.$t('identities.female'),
        this.$t('identities.nonBinary'),
        this.$t('identities.other'),
      ],
    };
  },
  watch: {
    locationText(value) {
      this.newUserData.city = value.split(',')[0];
      this.newUserData.place_id = this.place_id;
    }
  },
  computed: {
    user() {
      return this.$store.getters['UserStore/getUser'];
    },
    googleAutocompleteOptions() {
      const userCountry = this.$store.getters['UserStore/getCountry'];
      const countriesList = this.$store.getters['SearchStore/getCountriesList'];
      const countryIso2 = countriesList.find(
        (country) => country.name === userCountry || country.native === userCountry
      )?.iso2 || '';

      if (!countryIso2) {
        return {
          types: ['(cities)'],
        };
      }

      return {
        componentRestrictions: { country: countryIso2 },
        types: ['(cities)'],
      };
    },
  },
  methods: {
    onPhotoUpload(image) {
      this.newUserData.avatar = image;
      this.isUploadFinished = true;
    },
    clearDialog() {
      this.isDialogVisible = false;
      this.dialogMessage = '';
    },
    showDialog(message) {
      this.dialogMessage = message;
      this.isDialogVisible = true;
    },
    onUploadStart() {
      this.isUploadFinished = false;
    },

    async save() {
      const requiredFields = ['name', 'dateOfBirth', 'country', 'city'];

      const missingFields = requiredFields.filter(
        (field) => !this.newUserData[field]
      );

      if (missingFields.length) {
        this.showDialog(this.$t('dialog.pleaseFillAllRequiredFields'));
        setTimeout(this.clearDialog, 1000);
        return;
      }

      const isLessThan18 =
        new Date().getFullYear() - this.newUserData.dateOfBirth.getFullYear() <
        18;
      if (isLessThan18) {
        this.showDialog(this.$t('dialog.youMustBeAtLeast'));
        setTimeout(this.clearDialog, 1000);
        return;
      }

      await this.$store.dispatch('UserStore/updateUser', this.newUserData);

      jsConfetti.addConfetti({
        emojis: ['🎉', '🎈', '🎊', '💥', '✨', '💫', '🌸'],
        confettiNumber: 100,
        emojiSize: 40,
      });

      this.$router.push({ name: 'profile' });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-profile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: hidden;

  .location-predictions {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    overflow-y: auto;
  }

  .edit-profile-form {
    overflow-y: scroll;

    .element-wrapper {
      margin: 10px 12px;
    }

    .element-wrapper:last-of-type {
      margin-bottom: 0;
    }
  }

  .edit-profile-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 12px 0 12px;
  }
}
</style>
