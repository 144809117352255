<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.35762 3.10529C7.87583 2.2442 9.12423 2.2442 9.64244 3.10529L10.9006 5.19585L13.2776 5.74638C14.2567 5.97314 14.6424 7.16043 13.9836 7.91937L12.3842 9.76193L12.5951 12.1927C12.682 13.194 11.6721 13.9278 10.7467 13.5357L8.50003 12.5839L6.25339 13.5357C5.328 13.9278 4.31803 13.194 4.40493 12.1927L4.61589 9.76193L3.01643 7.91937C2.35762 7.16043 2.74339 5.97314 3.72248 5.74638L6.0995 5.19586L7.35762 3.10529ZM8.50003 3.79281L7.24191 5.88337C7.05574 6.19272 6.75208 6.41334 6.40034 6.49481L4.02332 7.04533L5.62278 8.88789C5.85946 9.16054 5.97545 9.51752 5.94423 9.87722L5.73327 12.308L7.97991 11.3562C8.31235 11.2154 8.6877 11.2154 9.02015 11.3562L11.2668 12.308L11.0558 9.87722C11.0246 9.51752 11.1406 9.16054 11.3773 8.88789L12.9767 7.04533L10.5997 6.49481C10.248 6.41334 9.94432 6.19272 9.75815 5.88337L8.50003 3.79281Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'StarSvg',
};
</script>
