<template>
  <div class="search-page d-flex flex-column">
    <div class="location-block py-2" v-if="
      (location?.city || couldNotRetrieveLocation)
    ">
      <LocationSvg />
      <div @click="showLocationMenu">
        {{
          couldNotRetrieveLocation ? $t('cardText.pleaseSelectLocation') : location?.city
        }}
      </div>
      <v-icon class="mt-1" icon="mdi-menu-down" />
    </div>
    <div class="location-placeholder py-2" v-else></div>

    <div class="filter-block my-2 px-3 d-flex justify-space-between">
      <div class="input-wrapper">
        <BaseInput v-model="searchQuery" placeholder="placeholder.search" append-inner-icon="mdi-magnify"
          @input="debouncedInputHandler" />
      </div>
      <div class="list-controls d-flex ms-3" v-if="false">
        <!-- <SortSvg /> -->
        <FilterSvg />
      </div>
    </div>
    <div class="search-page-tabs my-4 px-3 d-flex justify-space-around">
      <div class="tab" v-for="tab in tabNames" :key="tab" :class="{ 'is-active-tab': isActiveTab(tab) }">
        <div class="tab-name" @click="changeTab(tab)">{{ $t('label.' + tab) }}</div>
      </div>
    </div>

    <div class="skeleton-wrapper" v-if="isSearching">
      <v-skeleton-loader v-for="i in 10" :key="i" color="background" :height="150"
        type="list-item-avatar-three-line, actions" />
    </div>

    <v-window class="profile-category-details tab-wrapper" v-model="currentTab" v-else>
      <v-window-item :value="TabsEnum.EVENTS">
        <div class="search-items-list px-3" id="eventsList" v-if="events.length">
          <EventListItem v-for="event in events" :key="event.id" :item="event" class="my-3 py-3 px-1" />
        </div>
        <div class="no-results text-center" v-else>
          {{ $t('cardText.thereNoEventsFound') }}
        </div>
      </v-window-item>
      <v-window-item :value="TabsEnum.USERS">
        <div class="search-items-list px-3 pt-3" id="usersList" v-if="users.length">
          <UserListItem v-for="user in users" :key="user.id" :item="user" class="my-3" />
        </div>
        <div class="no-results text-center" v-else>
          {{ $t('cardText.thereNoUsersFound') }}
        </div>
      </v-window-item>

      <v-window-item :value="TabsEnum.GAMES">
        <div class="search-items-list px-3" id="gamesList" v-if="games.length">
          <keep-alive>
            <GameListItem v-for="game in games" :key="game" :game-id="game" :is-favorite="isGameFavorite(game)"
              @changeFavoriteStatus="toggleFavoriteStatus(game)" class="my-3 py-3 px-1" />
          </keep-alive>
        </div>
        <div class="no-results text-center" v-else>
          {{ $t('cardText.thereNoGamesFound') }}
        </div>
      </v-window-item>
    </v-window>
    <BaseDialog transition="dialog-bottom-transition" class="location-dialog" width="auto" v-model="isLocationMenuOpen">
      <v-card class="px-2 py-6">
        <v-card-text class="font-weight-medium text-center">
          {{ $t('cardText.enterLocation') }}
        </v-card-text>

        <v-card-text>
          <BaseInput v-model="locationQuery" placeholder="placeholder.cityOrAddress" append-inner-icon="mdi-magnify"
            @input="debouncedLocationHandler" />
          <div class="location-predictions" v-if="locationPredictions.length">
            <div class="prediction py-1 px-2" v-for="prediction in locationPredictions" :key="prediction.place_id"
              @click="handleLocationChange(prediction.place_id)">
              {{ prediction.description }}
            </div>
          </div>
          <div class="text-center my-2">{{ $t('other.or') }}</div>
          <div class="set-current-location">
            <BaseButton @click="restoreCurrentLocation" class="primary">{{ $t('button.setCurrentLocation') }}
            </BaseButton>
          </div>
        </v-card-text>
      </v-card>
    </BaseDialog>
  </div>
</template>

<script>
const TabsEnum = {
  EVENTS: 'events',
  USERS: 'users',
  GAMES: 'games',
};

const tabNames = [TabsEnum.EVENTS, TabsEnum.USERS, TabsEnum.GAMES];

const tabComponents = {
  [TabsEnum.EVENTS]: 'EventListItem',
  [TabsEnum.USERS]: 'UserListItem',
  [TabsEnum.GAMES]: 'GameListItem',
};

import EventListItem from '@/components/EventListItem.vue';
import UserListItem from '@/components/UserListItem.vue';
import GameListItem from '@/components/GameListItem.vue';
import BaseInput from '@/components/base/BaseInput.vue';
// import SortSvg from '@/components/icons/SortSvg.vue';
import LocationSvg from '@/components/icons/LocationSvg.vue';
import FilterSvg from '@/components/icons/FilterSvg.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import debounce from 'lodash/debounce';
import gameListItemMixin from '@/mixins/gameListItemMixin';
import locationInputMixin from '@/mixins/locationInputMixin';
import BaseDialog from '@/components/base/BaseDialog.vue';

export default {
  name: 'SearchPage',
  mixins: [gameListItemMixin, locationInputMixin],
  components: {
    BaseButton,
    EventListItem,
    UserListItem,
    GameListItem,
    // SortSvg,
    LocationSvg,
    FilterSvg,
    BaseInput,
    BaseDialog,
  },
  data() {
    const searchState = this.$store.getters['SearchStore/getSearchState'];
    let searchQuery = '';
    let currentTab = tabNames[0];
    let isStateRestored = false;

    if (searchState) {
      isStateRestored = true;
      searchQuery = searchState.searchQuery || '';
      currentTab = searchState.currentTab || tabNames[0];
    }

    return {
      isStateRestored,
      TabsEnum,
      searchQuery,
      debouncedInputHandler: debounce(this.searchItems, 400),
      tabNames,
      tabComponents,
      couldNotRetrieveLocation: false,
      isSearching: true,
      isLocationMenuOpen: false,
      locationQuery: '',
      currentTab,
    };
  },
  beforeRouteLeave(to, from, next) {
    const currentTabElement = document.getElementById(`${this.currentTab}List`);

    const searchState = {
      searchQuery: this.searchQuery,
      currentTab: this.currentTab,
      scrollPosition: currentTabElement?.scrollTop || 0,
    };

    this.$store.dispatch('SearchStore/saveSearchState', searchState);
    next();
  },
  async mounted() {
    if (this.isStateRestored) {
      this.isSearching = false;
      this.$nextTick(() => {
        const listElement = document.getElementById(`${this.currentTab}List`);
        if (!listElement) {
          return;
        }

        const searchState = this.$store.getters['SearchStore/getSearchState'];
        listElement.scrollTop = searchState.scrollPosition || 0;
        return;
      });
      return;
    }

    this.isSearching = true;
    await new Promise((resolve) => {
      if (window.cordova) {
        document.addEventListener('deviceready', resolve);
      } else {
        resolve();
      }
    });

    const isLocationAvailable = await this.$store.dispatch(
      'UserStore/fetchLocation'
    );

    if (!isLocationAvailable) {
      this.couldNotRetrieveLocation = true;
      this.isLocationMenuOpen = true;
    } else {
      await this.$store.dispatch('SearchStore/initialSearch');
    }
    this.isSearching = false;
  },
  computed: {
    isActiveTab() {
      return (tab) => this.currentTab === tab;
    },
    events() {
      return this.$store.getters['SearchStore/getEvents'];
    },
    users() {
      return this.$store.getters['SearchStore/getUsers'];
    },
    games() {
      return this.$store.getters['SearchStore/getGames'];
    },
    noResultsFromSearch() {
      return this.$store.getters['SearchStore/getNoResults'];
    },
    location() {
      return this.$store.getters['UserStore/getLocation'];
    },
  },
  methods: {
    async handleLocationChange(place_id) {
      this.place_id = place_id;
      this.locationQuery = '';
      this.isLocationMenuOpen = false;
      this.locationPredictions = [];
      this.searchQuery = '';
      this.isSearching = true;

      await this.$store.dispatch('UserStore/updateLocation', {
        placeId: this.place_id,
      });
      await Promise.all([
        this.$store.dispatch('SearchStore/search', {
          category: 'events',
          query: this.searchQuery || '',
        }),
        this.$store.dispatch('SearchStore/search', {
          category: 'users',
          query: this.searchQuery || '',
        })
      ]);
      this.couldNotRetrieveLocation = false;
      this.isSearching = false;
    },
    async restoreCurrentLocation() {
      this.locationQuery = '';
      this.isLocationMenuOpen = false;

      const currentLocation =
        this.$store.getters['UserStore/getCurrentLocation'];

      if (!currentLocation) {
        const isLocationAvailable = await this.$store.dispatch(
          'UserStore/fetchLocation'
        );

        this.couldNotRetrieveLocation = !isLocationAvailable;
      } else {
        await this.$store.dispatch('UserStore/restoreCurrentLocation');
      }

      await this.$store.dispatch('SearchStore/search', {
        category: 'events',
        query: this.searchQuery || '',
      });
    },
    async changeTab(tab) {
      if (this.currentTab === tab) {
        return;
      }

      this.searchQuery = '';

      if (tab === TabsEnum.GAMES) {
        this.$store.dispatch('SearchStore/restoreInitialResults');
        this.currentTab = tab;
        return;
      }

      const currLocation =
        this.$store.getters['UserStore/getCurrentCoordinates'];
      const selectedLocation = this.$store.getters['UserStore/getCoordinates'];

      if (
        currLocation?.lat !== selectedLocation?.lat ||
        currLocation?.lng !== selectedLocation?.lng
      ) {
        await this.$store.dispatch('SearchStore/search', {
          category: tab,
          query: this.searchQuery,
        });
        this.currentTab = tab;
        return;
      } else {
        this.$store.dispatch('SearchStore/restoreInitialResults');
        this.currentTab = tab;
      }
    },
    async searchItems() {
      this.isSearching = true;

      await this.$store.dispatch('SearchStore/search', {
        category: this.currentTab,
        query: this.searchQuery,
      });

      this.isSearching = false;
    },
    async confirmLocationChange() {
      this.isLocationMenuOpen = false;
      if (!this.locationQuery) {
        return;
      }

      await this.$store.dispatch('UserStore/updateLocation', {
        placeId: this.place_id,
      });
      await this.$store.dispatch('SearchStore/search', {
        category: 'events',
        query: this.searchQuery || '',
      });
    },
    async searchLocation() {
      if (!this.locationQuery) {
        return;
      }

      await this.$store.commit('UserStore/setLocation', this.locationQuery);
      this.isLocationMenuOpen = false;
    },
    showLocationMenu() {
      this.isLocationMenuOpen = true;
    },
    changeRoute(route) {
      this.$router.push({ name: route.name, params: { id: route.id } });
    },
  },
};
</script>

<style lang="scss">
.location-dialog {
  color: white;

  .location-predictions {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    overflow-y: auto;
  }
}

.search-page {
  height: calc(100% - 70px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: calc(env(safe-area-inset-top) + 20px);

  .tab {
    font-size: 16px;
    line-height: 16px;
    width: 30%;
    text-align: center;
  }

  .tab:not(.is-active-tab) {
    transition: font-size 0.1s ease-out;
  }

  .no-results {
    color: rgb(var(--v-theme-grey-icon));
    font-size: 16px;
    line-height: 16px;
  }

  .location-placeholder {
    min-height: 36px;
  }

  .v-window,
  .v-window__container,
  .v-window-item,
  .search-items-list {
    height: 100%;
  }

  .location-block {
    display: flex;
    align-items: center;
    align-self: center;
    width: fit-content;
    color: white;
    font-size: 16px;
    line-height: 16px;

    svg {
      width: 20px;
      height: 20px;
      fill: white;
    }

    i {
      font-size: 16px;
      line-height: 16px;
    }

    span {
      margin: 0 8px;
    }
  }

  .hide {
    display: none !important;
  }

  .tab-name {
    text-transform: capitalize;
  }

  .is-active-tab {
    color: rgb(var(--v-theme-primary));
    font-size: 20px;
    line-height: 20px;
    transition: font-size 0.3s ease-in;
  }

  .filter-block {
    .list-controls {
      align-items: center;
      justify-content: space-between;
    }

    .input-wrapper {
      width: 100%;
    }
  }

  .search-items-list {
    overflow-y: scroll;

    .image-block {
      display: flex;
      padding-top: 2px;
    }

    .game-list-item {
      border-bottom: 1px solid rgb(63, 65, 71);
      margin: 12px 0;
      padding: 12px 4px;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
</style>
