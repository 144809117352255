<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75178 5.29961C8.05825 6.09029 7.66667 7.23664 7.66667 8.6V10.3333C7.66667 11.1939 7.27938 11.9088 6.86882 12.4535C6.49335 12.9517 6.02576 13.399 5.65857 13.7502C5.62774 13.7797 5.59761 13.8085 5.56831 13.8366C5.22481 14.1662 5 14.6136 5 15.1333C5 15.6585 5.39364 16 5.77778 16H18.2222C18.6064 16 19 15.6585 19 15.1333C19 14.6136 18.7752 14.1662 18.4317 13.8366C18.4024 13.8085 18.3723 13.7797 18.3414 13.7502C17.9742 13.399 17.5066 12.9517 17.1312 12.4535C16.7206 11.9088 16.3333 11.1939 16.3333 10.3333V8.6C16.3333 7.23664 15.9417 6.09029 15.2482 5.29962C14.5713 4.52782 13.5251 4 12 4C10.4749 4 9.42875 4.52782 8.75178 5.29961ZM7.24822 3.98079C8.34903 2.72578 9.9695 2 12 2C14.0305 2 15.651 2.72578 16.7518 3.98079C17.836 5.21692 18.3333 6.87057 18.3333 8.6V10.3333C18.3333 10.5774 18.4428 10.8709 18.7283 11.2498C19.002 11.6129 19.3573 11.9534 19.7498 12.3296C19.7718 12.3508 19.794 12.372 19.8163 12.3934C20.5 13.0494 21 13.9996 21 15.1333C21 16.67 19.8018 18 18.2222 18H5.77778C4.19824 18 3 16.67 3 15.1333C3 13.9996 3.49997 13.0494 4.18372 12.3934C4.206 12.372 4.22817 12.3508 4.25022 12.3296C4.6427 11.9534 4.99798 11.6129 5.27166 11.2498C5.55716 10.8709 5.66667 10.5774 5.66667 10.3333V8.6C5.66667 6.87057 6.16397 5.21692 7.24822 3.98079Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.44313 19.1694C9.90183 18.8618 10.523 18.9843 10.8306 19.443C10.93 19.5912 11.0844 19.731 11.292 19.8345C11.4999 19.938 11.7445 19.9964 12.0001 19.9964C12.2557 19.9964 12.5003 19.938 12.7082 19.8345C12.9158 19.731 13.0702 19.5912 13.1696 19.443C13.4772 18.9843 14.0984 18.8618 14.5571 19.1694C15.0158 19.4771 15.1382 20.0983 14.8306 20.557C14.5235 21.0149 14.0945 21.3783 13.6001 21.6246C13.1059 21.8708 12.5547 21.9964 12.0001 21.9964C11.4455 21.9964 10.8943 21.8708 10.4001 21.6246C9.90574 21.3783 9.47669 21.0149 9.16957 20.557C8.86196 20.0983 8.98444 19.4771 9.44313 19.1694Z" />
  </svg>


</template>

<script>
export default {
  name: 'BellSvg',
};
</script>
