<template>
  <div class="social-settings px-3 py-4 d-flex flex-column justify-space-between">
    <div class="inputs">
      <BaseInput v-model="instagram" class="mb-4" inputLabel="Instagram" placeholder="placeholder.enterInstagram" />
      <BaseInput v-model="bgg" class="mb-4" inputLabel="BoardGameGeek" placeholder="placeholder.enterBoardGameGeek" />
      <BaseInput v-model="telegram" class="mb-4" inputLabel="Telegram" placeholder="placeholder.enterTelegram" />
      <div class="social-networks-instructions">
        <div class="instruction-text">
          {{ $t('instructions.socialSettingsExample') }}
        </div>
        <div class="user-link pt-2">
          <span>https://instagram.com/</span><span class="user-name">geeker_app</span>
        </div>
      </div>
    </div>
    <div class="controls">
      <BaseButton class="save-button primary" @click="save">{{
        $t('button.save')
      }}</BaseButton>
    </div>
    <BaseDialog v-model="dialog.show" location="center" color="#2F313D" class="pa-2 social-settings-dialog">
      <v-card class="pa-8">
        <div class="dialog-text social">{{ dialog.text }}</div>
      </v-card>
    </BaseDialog>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';
import BaseButton from '@/components//base/BaseButton.vue';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

const userNameValidator = (value) => {
  if (!value) {
    return true;
  }

  return /^[a-zA-Z0-9_.-]*$/.test(value);
};


export default {
  name: 'SocialSettings',
  components: {
    BaseInput,
    BaseButton,
    BaseDialog,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      instagram: {
        userNameValidator,
      },
      bgg: {
        userNameValidator,
      },
      telegram: {
        userNameValidator,
      },
    };
  },
  data() {
    let instagram = '',
      bgg = '',
      telegram = '';
    const socialSettings = this.$store.getters['UserStore/getSocials'];

    if (socialSettings) {
      instagram = socialSettings.instagram;
      bgg = socialSettings.bgg;
      telegram = socialSettings.telegram;
    }

    return {
      dialog: {
        show: false,
        text: '',
      },
      isSaveInProcess: false,
      instagram,
      bgg,
      telegram,
    };
  },
  async mounted() {
    try {
      if (!this.socials) {
        await this.$store.dispatch('UserStore/fetchSocialSettings');
        this.$nextTick(() => {
          this.instagram = this.socials?.instagram;
          this.bgg = this.socials?.bgg;
          this.telegram = this.socials?.telegram;
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    userId() {
      return this.$store.getters['UserStore/getUserId'];
    },
    socials() {
      return this.$store.getters['UserStore/getSocials'];
    },
  },
  methods: {
    async save() {
      if (this.isSaveInProcess) {
        return;
      }

      if (this.v$.$invalid) {
        this.dialog = {
          show: true,
          text: this.$t('dialog.socialUsernameError'),
        };
        return;
      }

      this.isSaveInProcess = true;
      const socialSettings = {
        instagram: this.instagram,
        bgg: this.bgg,
        telegram: this.telegram,
      };

      try {
        const isUpdated = await this.$store.dispatch(
          'UserStore/updateSocialSettings',
          socialSettings
        );

        if (isUpdated) {
          this.dialog = {
            show: true,
            text: this.$t('dialog.socialSettingsUpdated'),
          };
          setTimeout(() => {
            this.dialog.show = false;
          }, 1500);
        } else {
          this.dialog = {
            show: true,
            text: this.$t('dialog.failedUpdateSocialSettings'),
          };
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isSaveInProcess = false;
      }
    },
  },
};
</script>

<style lang="scss">
.social-settings {
  height: 100%;

  .social-networks-instructions {
    .instruction-text {
      color: #8e8e8e;
      font-weight: 400;
    }

    .user-link {
      display: flex;
      color: #999999;
      align-items: center;

      .user-name {
        color: rgb(var(--v-theme-primary));
        font-weight: 700;
      }
    }
  }
}

.dialog-text.social {
  text-align: center !important;
}
</style>
