<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2747 4.11849C12.0952 4.06719 11.9048 4.06719 11.7253 4.11849L6.72528 5.54706C6.29598 5.66972 6 6.06211 6 6.50859V11.883C6 14.1437 7.09176 16.2652 8.93133 17.5792L11.4188 19.3559C11.7665 19.6043 12.2335 19.6043 12.5812 19.3559L15.0687 17.5792C16.9082 16.2652 18 14.1437 18 11.883V6.50859C18 6.06211 17.704 5.66972 17.2747 5.54706L12.2747 4.11849ZM11.1758 2.19545C11.7145 2.04154 12.2855 2.04154 12.8242 2.19545L17.8242 3.62402C19.1121 3.99199 20 5.16915 20 6.50859V11.883C20 14.7896 18.5963 17.5172 16.2311 19.2066L13.7437 20.9834C12.7006 21.7284 11.2994 21.7284 10.2563 20.9834L7.76886 19.2066C5.40369 17.5172 4 14.7896 4 11.883V6.50859C4 5.16915 4.88793 3.99199 6.17584 3.62402L11.1758 2.19545Z" />
  </svg>


</template>

<script>
export default {
  name: 'ShieldSvg',
};
</script>
