<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.28587 3.78587C4.49157 2.58017 6.15915 1.83325 7.99992 1.83325C9.84068 1.83325 11.5083 2.58017 12.714 3.78587C13.9197 4.99157 14.6666 6.65915 14.6666 8.49992C14.6666 10.3407 13.9197 12.0083 12.714 13.214C11.5083 14.4197 9.84068 15.1666 7.99992 15.1666C6.15915 15.1666 4.49157 14.4197 3.28587 13.214C2.08017 12.0083 1.33325 10.3407 1.33325 8.49992C1.33325 6.65915 2.08017 4.99157 3.28587 3.78587ZM7.99992 3.16659C6.52698 3.16659 5.19456 3.76281 4.22868 4.72868C3.26281 5.69456 2.66659 7.02698 2.66659 8.49992C2.66659 9.97286 3.26281 11.3053 4.22868 12.2712C5.19456 13.237 6.52698 13.8333 7.99992 13.8333C9.47286 13.8333 10.8053 13.237 11.7712 12.2712C12.737 11.3053 13.3333 9.97286 13.3333 8.49992C13.3333 7.02698 12.737 5.69456 11.7712 4.72868C10.8053 3.76281 9.47286 3.16659 7.99992 3.16659Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99992 5.16675C8.36811 5.16675 8.66659 5.46522 8.66659 5.83341V8.50008C8.66659 8.86827 8.36811 9.16675 7.99992 9.16675C7.63173 9.16675 7.33325 8.86827 7.33325 8.50008L7.33325 5.83341C7.33325 5.46522 7.63173 5.16675 7.99992 5.16675Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.52851 8.02851C7.78886 7.76816 8.21097 7.76816 8.47132 8.02851L9.80466 9.36185C10.065 9.6222 10.065 10.0443 9.80466 10.3047C9.54431 10.565 9.1222 10.565 8.86185 10.3047L7.52851 8.97132C7.26816 8.71097 7.26816 8.28886 7.52851 8.02851Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'ClockSvg',
};
</script>
