<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.80957 12.5954C3.80957 11.1489 4.98216 9.97632 6.42862 9.97632H9.57147C11.0179 9.97632 12.1905 11.1489 12.1905 12.5954C12.1905 13.4632 11.487 14.1668 10.6191 14.1668H5.381C4.51312 14.1668 3.80957 13.4632 3.80957 12.5954ZM6.42862 11.0239C5.56074 11.0239 4.85719 11.7275 4.85719 12.5954C4.85719 12.8847 5.09171 13.1192 5.381 13.1192H10.6191C10.9084 13.1192 11.1429 12.8847 11.1429 12.5954C11.1429 11.7275 10.4394 11.0239 9.57147 11.0239H6.42862Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00015 4.73829C7.13228 4.73829 6.42872 5.44185 6.42872 6.30972C6.42872 7.1776 7.13228 7.88115 8.00015 7.88115C8.86803 7.88115 9.57158 7.1776 9.57158 6.30972C9.57158 5.44185 8.86803 4.73829 8.00015 4.73829ZM5.3811 6.30972C5.3811 4.86326 6.55369 3.69067 8.00015 3.69067C9.44661 3.69067 10.6192 4.86326 10.6192 6.30972C10.6192 7.75618 9.44661 8.92877 8.00015 8.92877C6.55369 8.92877 5.3811 7.75618 5.3811 6.30972Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.85733 4.21437C3.98945 4.21437 3.2859 4.91792 3.2859 5.7858C3.2859 6.65367 3.98945 7.35722 4.85733 7.35722C5.10093 7.35722 5.33158 7.3018 5.53736 7.20286C5.65924 7.53883 5.84792 7.84277 6.08733 8.09864C5.72059 8.29408 5.3019 8.40484 4.85733 8.40484C3.41087 8.40484 2.23828 7.23226 2.23828 5.7858C2.23828 4.33934 3.41087 3.16675 4.85733 3.16675C5.61204 3.16675 6.2922 3.48597 6.77014 3.99677C6.45691 4.1637 6.18158 4.3924 5.96042 4.66661C5.67671 4.38695 5.28717 4.21437 4.85733 4.21437ZM9.91298 8.0986C10.2797 8.29406 10.6984 8.40484 11.143 8.40484C12.5895 8.40484 13.7621 7.23226 13.7621 5.7858C13.7621 4.33934 12.5895 3.16675 11.143 3.16675C10.3883 3.16675 9.70814 3.48599 9.2302 3.99681C9.54343 4.16374 9.81875 4.39245 10.0399 4.66667C10.3236 4.38698 10.7132 4.21437 11.143 4.21437C12.0109 4.21437 12.7145 4.91792 12.7145 5.7858C12.7145 6.65367 12.0109 7.35722 11.143 7.35722C10.8994 7.35722 10.6687 7.30178 10.4629 7.20282C10.341 7.53879 10.1524 7.84273 9.91298 8.0986Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.666748 12.0714C0.666748 10.625 1.83934 9.45239 3.2858 9.45239H6.42865C7.01826 9.45239 7.56235 9.64722 8.00008 9.97601C8.43781 9.64722 8.9819 9.4524 9.5715 9.4524H12.7144C14.1608 9.4524 15.3334 10.625 15.3334 12.0714C15.3334 12.9393 14.6299 13.6429 13.762 13.6429H11.7904C12.0393 13.3649 12.1906 12.9977 12.1906 12.5953L13.762 12.5953C14.0513 12.5953 14.2858 12.3607 14.2858 12.0714C14.2858 11.2036 13.5822 10.5 12.7144 10.5H11.1432C10.7054 10.1711 10.1612 9.97621 9.57153 9.97621H6.42867C5.83895 9.97621 5.29476 10.1711 4.85699 10.5H3.2858C2.41792 10.5 1.71437 11.2036 1.71437 12.0714C1.71437 12.3607 1.94888 12.5952 2.23818 12.5952H3.80962C3.80962 12.5953 3.80962 12.5953 3.80962 12.5953C3.80962 12.9977 3.96092 13.3649 4.20975 13.6429H2.23818C1.3703 13.6429 0.666748 12.9393 0.666748 12.0714Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'CommunitySvg',
};
</script>
