<template>
  <nav
    class="bottom-navigation-block justify-content-around align-items-center w-100"
  >
    <div
      v-for="item in bottomMenu"
      :key="item.value"
      class="menu-item d-flex"
      @click="item.handler(item)"
      :class="{ active: isActive(item.value) }"
    >
      <v-icon>{{ item.icon }}</v-icon>
    </div>
  </nav>
</template>

<script>
class MenuItem {
  constructor(value, icon, handler = () => {}) {
    this.value = value;
    this.icon = icon;
    this.handler = handler;
  }
}

export default {
  data() {
    const bottomMenu = [
      new MenuItem('search', 'mdi-magnify', this.changeTab),
      new MenuItem('createEvent', 'mdi-plus-circle-outline', this.changeTab),
      new MenuItem('notifications', 'mdi-bell-outline', this.changeTab),
      new MenuItem('profile', 'mdi-account-circle', this.changeTab),
    ];
    return {
      bottomMenu,
      selectedItem: bottomMenu[0],
    };
  },
  methods: {
    changeTab(item) {
      if (this.appState !== item.value) {
        this.$router.push({ name: item.value });
      }
    },
  },
  computed: {
    appState() {
      return this.$route.name;
    },
    isActive() {
      return (value) => this.appState === value;
    },
  },
};
</script>

<style lang="scss">
.bottom-navigation-block {
  position: fixed;
  z-index: 1;
  bottom: 0;
  height: calc(70px + env(safe-area-inset-bottom));
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-top: 1px solid rgb(63, 65, 71);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 1);

  .menu-item {
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .v-icon {
      transition: margin-bottom 0.2s ease-in-out;
    }

    &:hover {
      .v-icon {
        margin-bottom: 10px;
      }
    }

    &.active {
      .v-icon {
        margin-bottom: 10px;
        color: rgb(var(--v-theme-primary));
      }
    }
  }
}
</style>
