<template>
  <v-app :class="{
    'logged-in': isLoggedIn,
    'bottom-navigation-visible': bottomNavigationVisibleClass,
    'top-toolbar-visible': isTopToolbarVisible,
    mobile: isMobile,
  }">
    <v-theme-provider>
      <ApplicationToolbar v-if="isTopToolbarVisible" />
      <div class="page-content">
        <router-view v-slot="{ Component }" :key="$route.fullPath">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
      <BottomNavigation v-if="isPageWithBottomNavigation" />
    </v-theme-provider>
  </v-app>
</template>

<script>
import ApplicationToolbar from '@/components/ApplicationToolbar.vue';
import BottomNavigation from '@/components/BottomNavigation.vue';
import ServerRequest from '@/utils/ServerRequest';
import decryptionUtils from '@/utils/decryptionUtils';
import vuetifyLocaleMap from '@/constants/vuetifyLanguagesMap';


export default {
  name: 'App',
  components: {
    BottomNavigation,
    ApplicationToolbar,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['UserStore/isLoggedIn'];
    },
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
    isPageWithToolbar() {
      return (
        this.$route.name !== 'login' &&
        this.$route.name !== 'signUp' &&
        this.$route.name !== 'event' &&
        this.$route.name !== 'search' &&
        this.$route.name !== 'gameInfo' &&
        this.$route.name !== 'resetPassword'
      );
    },
    isTopToolbarVisible() {
      return this.isLoggedIn && this.isPageWithToolbar;
    },
    isPageWithBottomNavigation() {
      return (
        this.isLoggedIn &&
        this.$route.name !== 'login' &&
        this.$route.name !== 'signUp'
      );
    },
    bottomNavigationVisibleClass() {
      return (
        this.isPageWithBottomNavigation &&
        !this.isEventPage &&
        !this.isGameDetailsPage &&
        !this.isSearchPage
      );
    },
    isSearchPage() {
      return this.$route.name === 'search';
    },
    isEventPage() {
      return this.$route.name === 'event';
    },
    isGameDetailsPage() {
      return this.$route.name === 'gameInfo';
    },
    isMobile() {
      return !!window.cordova;
    },
  },
  watch: {
    isLoggedIn() {
      this.addGoogleMapsScript();
      setTimeout(() => {
        this.getFirebaseToken();
      }, 10000);
    },
  },
  methods: {
    async getMyFavoriteGames() {
      const userId = this.$store.getters['UserStore/getUserId'];
      await this.$store.dispatch('UserStore/fetchFavoriteGames', {
        userId,
      });
    },
    async addGoogleMapsScript() {
      if (document.getElementById('google-maps-script')) {
        return;
      }

      let endpoint = 'gm-web';

      if (this.isMobile) {
        endpoint = 'gm-web-cordova';
      }

      const { encryptedKey } = await ServerRequest.get('options', endpoint);
      const decryptedKey = await decryptionUtils.decryptApiKey(encryptedKey);

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${decryptedKey}&libraries=places&loading=async`;
      script.async = true;
      script.defer = true;
      script.id = 'google-maps-script';
      document.head.appendChild(script);
    },
    async getFirebaseToken() {
      try {
        if (!this.isLoggedIn) {
          return;
        }

        const user = this.$store.getters['UserStore/getUser'];
        const st = user.sessionToken || '';

        if (!st || !window.cordova || !window.FirebasePlugin) {
          return;
        }

        const hasPermission = await new Promise((resolve, reject) => {
          window.FirebasePlugin.hasPermission(resolve, reject);
        });

        if (!hasPermission) {
          await new Promise((resolve, reject) => {
            window.FirebasePlugin.grantPermission(resolve, reject);
          });
        }

        await new Promise((resolve, reject) => {
          if (window.device.platform === 'iOS') {
            window.FirebasePlugin.getAPNSToken((apnsToken) => {
              if (!apnsToken) {
                console.log('Error while getting APNS token');
              }
              resolve();
            }, reject);
          } else {
            resolve();
          }
        });

        await new Promise((resolve, reject) => {
          window.FirebasePlugin.onTokenRefresh(async (newToken) => {
            await ServerRequest.post('users', 'firebase-token', {
              st,
              token: newToken,
            });
            resolve();
          }, reject);
        });
      } catch (error) {
        console.log('Error while getting firebase token');
        console.log(console.error);
      }
    },
    async addOnMessageReceivedListener() {
      if (!window.cordova) {
        return;
      }

      await new Promise((resolve) => {
        document.addEventListener('deviceready', resolve, false);
      });

      if (window.IonicDeeplink) {
        window.IonicDeeplink.route(
          {
            '/eventId/:id': 'eventId',
            '/profileId/:id': 'profileId',
            '/gameId/:id': 'gameId',
          },
          (match) => {
            let name, params;
            switch (match.$route) {
              case 'eventId':
                name = 'event';
                params = { id: match.$args.id };
                break;
              case 'profileId':
                name = 'profileId';
                params = { id: match.$args.id };
                break;
              case 'gameId':
                name = 'gameInfo';
                params = { id: match.$args.id };
                break;
            }

            const currentRoute = this.$route.name;
            const currentId = this.$route.params.id;

            if (currentRoute === name && currentId === params.id) {
              return;
            }

            if (currentRoute === 'event' && name === 'event') {
              this.$store.commit('EventStore/setEventData', null);
            }

            if (currentRoute === name) {
              this.$router.replace({ name, params });
            } else {
              this.$router.push({ name, params });
            }
          },
          (nomatch) => {
            window.console.log('IonicDeeplink nomatch:', nomatch);
          }
        );
      }
      window.FirebasePlugin.onMessageReceived(
        this.onMessageReceived,
        (error) => {
          console.error('onMessageReceived error:', error);
        }
      );
    },
    async onMessageReceived(message) {
      const type = message.type;

      const isTapped =
        message.tap &&
        (message.tap === 'background' || message.tap === 'foreground');

      if (!isTapped) {
        return;
      }
      switch (type) {
        case 'event': {
          const id = message.eventId || message.aps.eventId;
          this.$store.commit('EventStore/setEventData', null);
          this.$router.push({
            name: 'event',
            params: { id },
          });
          break;
        }
        case 'follower': {
          const id = message.followerId || message.aps.followerId;
          this.$router.push({
            name: 'profileId',
            params: { id },
          });
          break;
        }
        default:
          window.console.log('Unknown notification type: ', data);
          break;
      }
    },
  },

  beforeMount() {
    const storedLocale = localStorage.getItem('selectedLanguage');
    const navigatorLanguage = navigator.language.split?.('-')[1]?.toLowerCase?.();
    let languageCode;

    if (storedLocale) {
      languageCode = storedLocale;
    } else if (
      (navigatorLanguage && navigatorLanguage === 'ua') ||
      navigatorLanguage === 'ru'
    ) {
      languageCode = 'ua';
    } else {
      languageCode = 'en';
    }

    languageCode = languageCode;
    this.$i18n.locale = languageCode;
    window.console.log('languageCode', languageCode);
    this.$vuetify.locale.current = vuetifyLocaleMap[languageCode];
  },

  async mounted() {
    this.addGoogleMapsScript();
    document.addEventListener('focusout', function () {
      //TODO: for some cases, like phone authentication - we don't need such behavior (it jumps up and down)
      window.scrollTo(0, 0);
    });

    window.addEventListener('keyboardDidShow', () => {
      this.$store.commit('SettingsStore/setKeyboardVisible', true);
    });
    window.addEventListener('keyboardDidHide', () => {
      this.$store.commit('SettingsStore/setKeyboardVisible', false);
    });

    await this.$store.dispatch('UserStore/refreshUser');
    await this.$store.dispatch('UserStore/refreshFavoriteGames');

    if (!this.isLoggedIn) {
      this.$router.push({ name: 'login' });
      return;
    }

    this.getFirebaseToken();
    this.addOnMessageReceivedListener();
  },
};
</script>

<style lang="scss">
body {
  background-color: #2f313d;
  height: 100dvh;
  width: 100vw;
  font-family: 'Exo 2';
  touch-action: all;
  overflow: hidden;
}

html {
  touch-action: none;
}

h3 {
  font-weight: normal;
}

html,
body {
  overflow: hidden !important;
  overscroll-behavior: none;
}

.page-content {
  max-height: calc(100dvh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  height: 100%;
  overflow-y: scroll;

  .bottom-navigation-visible & {
    max-height: calc(100dvh - 140px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  }
}

.base-input-wrapper {
  .v-field__append-inner {
    padding-top: 10px;

    .mobile & {
      padding-top: 0;
    }
  }
}

@font-face {
  font-family: 'Exo 2';
  src: local('Exo 2'),
    url('assets/fonts/Exo2-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 1000;
}

@font-face {
  font-family: 'Exo 2';
  src: local('Exo 2'),
    url('assets/fonts/Exo2-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 1000;
  font-style: italic;
}
</style>
