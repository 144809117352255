<template>
  <div>
    <image-viewer :visible="image && imageViewerActive" :image-url="image" @close="imageViewerActive = false" />
    <img class="photo" v-if="!!image" :src="image" v-bind="$attrs" alt="" @click="viewFullScreenImage" />
  </div>
</template>

<script>
import ImageViewer from '@/components/ImageViewer.vue';
export default {
  name: 'PhotoBlock',
  components: {
    ImageViewer,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      imageViewerActive: false,
    };
  },
  methods: {
    viewFullScreenImage() {
      this.imageViewerActive = true;
    },
  },
};
</script>
