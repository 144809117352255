<template>
  <div class="toolbar-wrapper">
    <div class="back-button">
      <v-icon v-if="isBackButtonVisible" @click="toPreviousPage" icon="mdi-arrow-up"></v-icon>
      <div v-else class="empty-placeholder"></div>
    </div>
    <div class="title">{{ toolbarLabel }}</div>
    <div class="actions">
      <div v-if="hasSettings" class="settings-control" @click="openSettings">
        <SettingsSvg />
      </div>
      <div v-else-if="isProfileIdPage" class="profile-more" @click="openProfileMoreMenu">
        <BottomMenu :menuItems="menuItems" />
      </div>
      <div v-else class="empty-placeholder"></div>
    </div>
    <YesNoDialog v-model="isBlockDialogVisible" :dialog-text="$t('dialog.sureToBlock', { name: profileName })"
      @confirm="blockUser" @cancel="onCancelBlock" :is-cancel-red="false" :is-confirm-red="true" />
    <ReportDialog v-model="isReportDialogVisible" :name="profileName" :user-id="profileId" report-type="user" />
  </div>
</template>

<script>

const backButtonStates = {
  search: false,
  profile: false,
  settings: true,
  profileId: true,
  usersList: true,
  selectGame: true,
  aboutRating: true,
  editProfile: true,
  createEvent: false,
  blockedUsers: true,
  privacyPolicy: true,
  resetPassword: false,
  notifications: false,
  socialSettings: true,
  premiumSettings: true,
  securitySettings: true,
  myInvitedMembers: true,
  notificationsSettings: true,
};

import SettingsSvg from '@/components/icons/SettingsSvg.vue';
import BottomMenu from '@/components/BottomMenu.vue';
import YesNoDialog from '@/components/views/YesNoDialog.vue';
import ReportDialog from '@/components/views/ReportDialog.vue';
import { landing } from '@/constants/urls';
import SharingUtils from '@/utils/SharingUtils';

export default {
  name: 'ApplicationToolbar',
  components: {
    SettingsSvg,
    YesNoDialog,
    ReportDialog,
    BottomMenu,
  },
  data() {
    return {
      isSheetActive: false,
      isReportDialogVisible: false,
      isBlockDialogVisible: false,
    };
  },
  computed: {
    toolbarLabel() {
      if (this.isProfilePage) {
        return this.profileName;
      } else if (this.$route.name === 'usersList') {
        return this.$store.getters['SettingsStore/getUsersListPageTitle'];
      } else if (this.$route.name) {
        return this.$t(`title.${this.$route.name}`) || '';
      } else {
        return '';
      }
    },
    isProfilePage() {
      return this.isMyProfile || this.isProfileIdPage;
    },
    isMyProfile() {
      return this.$route.name === 'profile';
    },
    isProfileIdPage() {
      return this.$route.name === 'profileId' && this.$route.params.id;
    },
    isBackButtonVisible() {
      return backButtonStates[this.$route.name] || false;
    },
    profileId() {
      return this.isProfileIdPage ? this.$route.params.id : '';
    },
    currentUserId() {
      return this.$store.getters['UserStore/getUserId'];
    },
    isBlockedByMe() {
      return this.isProfileIdPage && this.$store.getters['UserStore/isBlockedByMe'](this.profileId);
    },
    menuItems() {
      const menuItems = [
        {
          title: this.$t('menuItem.shareAccount', this.profileName),
          icon: 'mdi-share-variant',
          isVisible: true,
          color: '#FFFFFF',
          handler: this.shareAccount,
        },
        {
          title: this.$t('menuItem.reportProfile', this.profileName),
          icon: 'mdi-flag-outline',
          isVisible: this.profileId !== this.currentUserId && !this.isBlockedByMe,
          color: '#D63B3B',
          handler: () => {
            this.showReportDialog();
          },
        },
        {
          title: this.$t('menuItem.block', this.profileName),
          icon: 'mdi-block-helper',
          isVisible: this.profileId !== this.currentUserId && !this.isBlockedByMe,
          color: '#D63B3B',
          handler: () => {
            this.showBlockDialog();
          },
        },
      ];

      return menuItems.filter((item) => item.isVisible);
    },
    profileName() {
      if (this.$route.params.id) {
        const user = this.$store.getters['UserStore/getUserById'](
          this.$route.params.id
        );
        return user?.name || '';
      } else {
        return this.$store.getters['UserStore/getUser'].name;
      }
    },
    hasSettings() {
      return this.isMyProfile;
    },
  },
  methods: {
    toPreviousPage() {
      this.$router.go(-1);
    },
    openSettings() {
      this.$router.push({ name: 'settings' });
    },
    showReportDialog() {
      this.isReportDialogVisible = true;
    },
    showBlockDialog() {
      this.isBlockDialogVisible = true;
    },
    closeReportDialog() {
      this.isReportDialogVisible = false;
    },
    async blockUser() {
      this.isBlockDialogVisible = false;
      await this.$store.dispatch('UserStore/blockUser', this.profileId);
      await this.$store.dispatch('UserStore/refreshUser')
      this.$router.go(-1);
    },
    onCancelBlock() {
      this.isBlockDialogVisible = false;
    },
    shareAccount() {
      const user = this.$store.getters['UserStore/getUserById'](
        this.$route.params.id
      );
      const link = `${landing}/profileId/${user._id}`;
      this.isSheetActive = false;
      SharingUtils.share({ title: 'Share profile', text: user.name, url: link });
    },
  },
};
</script>

<style lang="scss">
.bottom-menu {
  width: 100%;

  .v-list,
  .empty-list {
    padding-top: 20px;
    border-radius: 30px 30px 0 0;

    i {
      color: #ffffff;
    }

    .v-list-item--one-line {
      padding: 0;
      min-height: none;
    }
  }

  .separator {
    width: 30%;
    height: 3px;
    background-color: #c7c7c7;
    border-radius: 10px;
    margin: 10px 0;
    margin-inline: auto;
  }



  .v-list-item {
    padding: 0 32px;
  }

  .menu-item {
    display: flex;
    align-items: center;
  }

  .menu-item-icon {
    margin-right: 16px;
    color: rgb(var(--v-theme-grey-icon));
  }

  .menu-item-text {
    font-family: Exo 2;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
}

.toolbar-wrapper {
  display: flex;
  margin-top: env(safe-area-inset-top);
  height: 70px;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 20px;
    line-height: 20px;
  }

  .settings-control {
    width: 24px;
    height: 24px;

    svg {
      fill: #a7a7a7;
    }
  }

  .back-button {
    display: flex;
    justify-content: flex-end;
  }

  .back-button i {
    transform: rotate(-90deg);
  }

  .back-button,
  .actions {
    width: 36px;
  }

  .actions i {
    transform: rotate(90deg);
  }

  i {
    height: 20px;
    color: #a7a7a7;
  }

  .empty-placeholder {
    width: 14px;
    height: 14px;
  }
}
</style>
