<template>
  <div class="basic-login-block">
    <BaseInput placeholder="placeholder.email" inputLabel="label.email" class="my-2" v-model="email" />
    <small v-if="v$.email.$error" class="error">{{ v$ && v$.email.$errors[0].$message }}</small>
    <BaseInput placeholder="placeholder.password" inputLabel="label.password" class="my-2"
      :append-inner-icon="isPasswordShown ? 'mdi-eye-off' : 'mdi-eye'" @click:append-inner="togglePasswordVisibility"
      :type="isPasswordShown ? 'text' : 'password'" v-model="password" />
    <small v-if="v$.password.$error" class="error">{{ v$ && v$.password.$errors[0].$message }}</small>
    <span class="forgot-password" @click="resetPassword">{{ $t('label.forgotPassword') }}</span>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import { required, email, minLength, maxLength, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  name: 'EmailLoginForm',
  components: {
    BaseInput,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return { email: '', password: '', isPasswordShown: false };
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage(
          this.$t('validation.emailRequired'),
          required
        ),
        email: helpers.withMessage(
          this.$t('validation.emailInvalid'),
          email
        ),
      },
      password: {
        required: helpers.withMessage(
          this.$t('validation.passwordRequired'),
          required
        ),
        minLength: helpers.withMessage(
          this.$t('validation.passwordMinLength'),
          minLength(8)
        ),
        maxLength: helpers.withMessage(
          this.$t('validation.passwordMaxLength'),
          maxLength(20)
        ),
        containsUppercase: helpers.withMessage(
          this.$t('validation.passwordContainsUppercase'),
          value => /[A-Z]/.test(value)
        ),
        containsLowercase: helpers.withMessage(
          this.$t('validation.passwordContainsLowercase'),
          value => /[a-z]/.test(value)
        ),
        containsNumber: helpers.withMessage(
          this.$t('validation.passwordContainsNumber'),
          value => /[0-9]/.test(value)
        ),
      },
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.isPasswordShown = !this.isPasswordShown;
    },
    resetPassword() {
      this.$router.push({ name: 'resetPassword' });
    },
  },
};
</script>

<style lang="scss">
.basic-login-block {
  margin-top: 16px;

  .forgot-password {
    padding-inline: 2px;
    color: rgb(var(--v-theme-primary));
    cursor: pointer;
  }

  .error {
    display: flex;
    color: red;
  }
}
</style>
