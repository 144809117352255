<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 3.83333V13.1667H3.33333V3.83333H12.6667ZM12.6667 2.5H3.33333C2.6 2.5 2 3.1 2 3.83333V13.1667C2 13.9 2.6 14.5 3.33333 14.5H12.6667C13.4 14.5 14 13.9 14 13.1667V3.83333C14 3.1 13.4 2.5 12.6667 2.5ZM5 4.5C4.46667 4.5 4 4.96667 4 5.5C4 6.03333 4.46667 6.5 5 6.5C5.53333 6.5 6 6.03333 6 5.5C6 4.96667 5.53333 4.5 5 4.5ZM11 10.5C10.4667 10.5 10 10.9667 10 11.5C10 12.0333 10.4667 12.5 11 12.5C11.5333 12.5 12 12.0333 12 11.5C12 10.9667 11.5333 10.5 11 10.5ZM11 7.5C10.4667 7.5 10 7.96667 10 8.5C10 9.03333 10.4667 9.5 11 9.5C11.5333 9.5 12 9.03333 12 8.5C12 7.96667 11.5333 7.5 11 7.5ZM11 4.5C10.4667 4.5 10 4.96667 10 5.5C10 6.03333 10.4667 6.5 11 6.5C11.5333 6.5 12 6.03333 12 5.5C12 4.96667 11.5333 4.5 11 4.5ZM5 7.5C4.46667 7.5 4 7.96667 4 8.5C4 9.03333 4.46667 9.5 5 9.5C5.53333 9.5 6 9.03333 6 8.5C6 7.96667 5.53333 7.5 5 7.5ZM5 10.5C4.46667 10.5 4 10.9667 4 11.5C4 12.0333 4.46667 12.5 5 12.5C5.53333 12.5 6 12.0333 6 11.5C6 10.9667 5.53333 10.5 5 10.5Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'DiceSvg',
};
</script>
