<template>
  <div class="small-logo-wrapper">
    <img id="logo" src="../assets/images/geeker-logo.svg" />
  </div>
</template>

<script>
export default {
  name: 'SmallLogoBlock',
};
</script>

<style lang="scss" scoped>
.small-logo-wrapper {
  margin-top: calc(5% + env(safe-area-inset-top));
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  #logo {
    height: 56px;
    width: 56px;
  }
}
</style>
