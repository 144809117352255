<template>
  <div>
    <div class="base-autocomplete-label">{{ outerLabel ? $t(outerLabel) : '' }}</div>
    <v-autocomplete @click="() => console.log('clicked')" class="base-autocomplete" variant="outlined" single-line
      hide-details="auto" :placeholder="label" :items="items" v-model="value">
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'BaseAutocompleteSelect',
  props: {
    modelValue: [Object, String],
    label: {
      type: String,
      default: '- Select -',
    },
    outerLabel: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      value: this.modelValue || [],
    };
  },
  methods: {},
  watch: {
    value(val) {
      this.$emit('update:modelValue', val);
      this.$emit('change', val);
    },
  },
};
</script>

<style lang="scss">
.base-autocomplete-label {
  width: 100%;
  text-align: start;
}

.base-autocomplete {
  .v-field__input {
    line-height: 44px;
    min-height: 44px;
    padding: 0 12px;
  }

  .v-field__append-inner {
    padding: 0;
    align-items: center;
  }
}

.v-list,
.v-list-item {
  background-color: rgb(var(--v-theme-darken-bg)) !important;
}
</style>
