<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929L7.29289 7.29289C7.68342 6.90237 8.31658 6.90237 8.70711 7.29289C9.09763 7.68342 9.09763 8.31658 8.70711 8.70711L5.41421 12L8.70711 15.2929C9.09763 15.6834 9.09763 16.3166 8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071L3.29289 12.7071Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 12C5 11.4477 5.3198 11 5.71429 11L14.2857 11C14.6802 11 15 11.4477 15 12C15 12.5523 14.6802 13 14.2857 13L5.71429 13C5.3198 13 5 12.5523 5 12Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 7C13 6.44772 13.4477 6 14 6H18C18.5523 6 19 6.44772 19 7V17C19 17.5523 18.5523 18 18 18H14C13.4477 18 13 17.5523 13 17V16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16V18C11 19.1046 11.8954 20 13 20H19C20.1046 20 21 19.1046 21 18V6C21 4.89543 20.1046 4 19 4H13C11.8954 4 11 4.89543 11 6V8C11 8.55228 11.4477 9 12 9C12.5523 9 13 8.55228 13 8V7Z" />
  </svg>


</template>

<script>
export default {
  name: 'ExitSvg',
};
</script>
