<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.33325 5.83333C1.33325 3.99238 2.82564 2.5 4.66659 2.5H11.3333C13.1742 2.5 14.6666 3.99238 14.6666 5.83333V8.5C14.6666 8.86819 14.3681 9.16667 13.9999 9.16667C13.6317 9.16667 13.3333 8.86819 13.3333 8.5V5.83333C13.3333 4.72876 12.4378 3.83333 11.3333 3.83333H4.66659C3.56202 3.83333 2.66659 4.72876 2.66659 5.83333V11.8333C2.66659 12.9379 3.56202 13.8333 4.66659 13.8333H7.99992C8.36811 13.8333 8.66659 14.1318 8.66659 14.5C8.66659 14.8682 8.36811 15.1667 7.99992 15.1667H4.66659C2.82564 15.1667 1.33325 13.6743 1.33325 11.8333V5.83333Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.33341 1.83325C5.7016 1.83325 6.00008 2.13173 6.00008 2.49992V4.49992C6.00008 4.86811 5.7016 5.16658 5.33341 5.16658C4.96522 5.16658 4.66675 4.86811 4.66675 4.49992V2.49992C4.66675 2.13173 4.96522 1.83325 5.33341 1.83325Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 7.16667C4 6.79848 4.29848 6.5 4.66667 6.5H11.3333C11.7015 6.5 12 6.79848 12 7.16667C12 7.53486 11.7015 7.83333 11.3333 7.83333H4.66667C4.29848 7.83333 4 7.53486 4 7.16667Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.6667 1.83325C11.0349 1.83325 11.3333 2.13173 11.3333 2.49992V4.49992C11.3333 4.86811 11.0349 5.16658 10.6667 5.16658C10.2985 5.16658 10 4.86811 10 4.49992V2.49992C10 2.13173 10.2985 1.83325 10.6667 1.83325Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9999 11.1666C11.2635 11.1666 10.6666 11.7635 10.6666 12.4999C10.6666 13.2363 11.2635 13.8333 11.9999 13.8333C12.7363 13.8333 13.3333 13.2363 13.3333 12.4999C13.3333 11.7635 12.7363 11.1666 11.9999 11.1666ZM9.33325 12.4999C9.33325 11.0272 10.5272 9.83325 11.9999 9.83325C13.4727 9.83325 14.6666 11.0272 14.6666 12.4999C14.6666 13.9727 13.4727 15.1666 11.9999 15.1666C10.5272 15.1666 9.33325 13.9727 9.33325 12.4999Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'CalendarSvg',
};
</script>
