<template>
  <div class="interests-block">
    <div class="description">
      <h1>{{ $t('interestsFormTitles.interests') }}</h1>
      <h3>{{ $t('interestsFormTitles.descriptionLine1') }}</h3>
      <h3>{{ $t('interestsFormTitles.descriptionLine2') }}</h3>
    </div>

    <div class="interests mt-4">
      <div class="search-game-wrapper">
        <BaseInput v-model="search" placeholder="placeholder.searchForGame" append-inner-icon="mdi-magnify"
          @input="onChangeSearchText" />
      </div>

      <div class="mt-4"><b>{{ $t('label.genres') }}</b></div>
      <div class="genre-chips-block my-2">
        <v-chip v-for="genre in filteredGenres" :key="genre"
          :class="['genre-chip', selectedGenres.includes(genre) && 'selected']" @click="toggleGenre(genre)">
          {{ genre }}
        </v-chip>
        <div></div>
      </div>

      <div class="mt-4"><b>{{ $t('label.games') }}*</b></div>
      <div class="interests-wrapper mt-1" v-if="topGames.length">
        <div class="interest" :class="isGameSelected(game.id) && 'selected'" :data-id="game.id"
          v-for="(game, index) in topGames" :key="index" @click="toggleGame(game)">
          <div class="image-wrapper">
            <img :src="game.thumbnail" alt="game thumbnail" :title="game.name" class="game-cover" />
          </div>

          <div v-show="isGameSelected(game.id)" class="selected-game-wrapper">
            <div class="inner-square">
              <img :src="checkIconUrl" class="selected-game-icon" :key="game.id" />
            </div>
          </div>
        </div>
      </div>
      <div class="no-games-found" v-else-if="search">
        {{ $t('label.noGamesFound') }}
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import genres from '@/assets/data/boardGameGenres.json';
import BoardGamesGeekApi from '@/utils/ApiUtils';

export default {
  name: 'InterestsForm',
  components: {
    BaseInput,
  },
  data() {
    return {
      search: '',
      previousSearchTimeout: 0,
      genres,
      selectedGenres: [],
      selectedGames: [],
      topGames: [],
      topGamesWithoutCriteria: [],
    };
  },
  async mounted() {
    this.topGames = await BoardGamesGeekApi.getHotItems();
    this.topGamesWithoutCriteria = JSON.parse(JSON.stringify(this.topGames));
  },
  methods: {
    toggleGenre(genre) {
      if (this.selectedGenres.includes(genre)) {
        this.selectedGenres = this.selectedGenres.filter(
          (selectedGenre) => selectedGenre !== genre
        );
      } else {
        this.selectedGenres.push(genre);
      }
    },
    toggleGame(game) {
      const isGameSelected = this.selectedGames.find(
        (selectedGame) => selectedGame.id === game.id
      );
      if (isGameSelected) {
        this.selectedGames = this.selectedGames.filter(
          (selectedGame) => selectedGame.id !== game.id
        );
      } else {
        this.selectedGames.push(game);
      }
    },
    async onChangeSearchText() {
      window.clearTimeout(this.previousSearchTimeout);

      if (!this.search || this.search.length < 3) {
        this.topGames = JSON.parse(
          JSON.stringify(this.topGamesWithoutCriteria)
        );
        return;
      }

      this.previousSearchTimeout = setTimeout(() => {
        this.getGames();
        clearTimeout(this.previousSearchTimeout);
      }, 500);
    },
    async getGames() {
      const searchQuery = this.search.trim().toLowerCase();
      this.topGames = (await BoardGamesGeekApi.search(searchQuery)).filter(
        (game) => game.thumbnail
      );
    },
  },
  computed: {
    isGameSelected() {
      return (gameId) =>
        !!this.selectedGames.find((game) => game.id === gameId);
    },
    filteredGenres() {
      return this.search
        ? this.genres.filter(
          (genre) =>
            this.selectedGenres.includes(genre) ||
            genre.toLowerCase().includes(this.search.toLowerCase())
        )
        : this.genres;
    },
    checkIconUrl() {
      return require('@/assets/images/check.svg');
    },
  },
};
</script>

<style lang="scss">
.interests-block {
  .description {
    text-align: center;
  }

  .genre-chips-block {
    display: flex;
    overflow-x: auto;
    gap: 10px;

    //hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }

    .genre-chip {
      min-width: fit-content;
      gap: 10px;
      height: 32px;
      font-size: 14px;
      line-height: 32px;
      border-radius: 8px;
      border: 1px solid rgb(var(--v-theme-primary));
      background-color: rgb(var(--v-theme-background));
      color: rgb(var(--v-theme-primary));
      cursor: pointer;

      &.selected {
        background-color: rgb(var(--v-theme-primary));
        color: #fff;
      }
    }
  }

  .interests-wrapper {
    width: 100%;
    height: 30dvh;
    overflow-y: scroll;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    justify-items: center;

    margin-top: -19px;

    &::-webkit-scrollbar {
      display: none;
    }

    .interest {
      display: flex;
      align-items: center;
      justify-content: space-around;

      user-select: none;
      -webkit-user-select: none;

      .image-wrapper {
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.1);
        width: 112px;
        height: 102px;

        img {
          object-fit: contain;
        }
      }

      &:nth-child(2n) .image-wrapper {
        height: 140px;
      }

      &:last-child .image-wrapper {
        margin-bottom: 40px;
      }

      &.selected {
        .image-wrapper {
          opacity: 0.3;
        }
      }

      .selected-game-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .inner-square {
          display: flex;
          width: 48px;
          height: 48px;
          align-items: center;
          justify-content: center;
          left: -78px;
          border-radius: 25%;
          background-color: rgb(var(--v-theme-primary));
          position: absolute;
        }

        .selected-game-icon {
          display: block;
          width: 24px;
          height: 24px;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }
}
</style>
