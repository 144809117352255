<template>
  <BaseDialog
    class="yes-no-dialog"
    v-model="isDialogActive"
    :persistent="$attrs.persistent || false"
  >
    <v-card class="py-12 px-8">
      <div class="dialog-icon">
        <WarningSvg />
      </div>
      <div class="dialog-text text-center">{{ translatedDialogText }}</div>
      <div class="dialog-actions">
        <BaseButton :class="{ red: isCancelRed }" @click="cancel">
          {{ translatedDialogNoText }}
        </BaseButton>
        <BaseButton :class="{ red: isConfirmRed }" @click="confirm">
          {{ translatedDialogYesText }}
        </BaseButton>
      </div>
      <div class="slot-wrapper">
        <slot />
      </div>
    </v-card>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import WarningSvg from '@/components/icons/WarningSvg.vue';

export default {
  name: 'YesNoDialog',
  components: {
    BaseDialog,
    BaseButton,
    WarningSvg,
  },
  props: {
    dialogText: {
      type: String,
      default: '',
    },
    dialogYesText: {
      type: String,
      default: '',
    },
    dialogNoText: {
      type: String,
      default: '',
    },
    isConfirmRed: {
      type: Boolean,
      default: false,
    },
    isCancelRed: {
      type: Boolean,
      default: false,
    },
    closeOnAction: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDialogActive: this.modelValue,
    };
  },
  watch: {
    modelValue(value) {
      this.isDialogActive = value;
    },
    isDialogActive(value) {
      this.$emit('update:modelValue', value);
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      if (this.closeOnAction) {
        this.isDialogActive = false;
      }
    },
    cancel() {
      this.$emit('cancel');
      this.isDialogActive = false;
    },
  },
  computed: {
    translatedDialogText() {
      return this.dialogText || this.$t('dialog.thisActionCannotBeUndone');
    },
    translatedDialogYesText() {
      return this.dialogYesText || this.$t('dialog.yes');
    },
    translatedDialogNoText() {
      return this.dialogNoText || this.$t('dialog.no');
    },
  },
};
</script>

<style lang="scss">
.yes-no-dialog {
  .dialog-icon {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    svg {
      fill: rgb(var(--v-theme-grey-icon));
    }
  }

  .dialog-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .base-button {
      width: 45%;
      color: white;
    }
  }
}
</style>
