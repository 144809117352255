<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="&#208;&#161;&#208;&#187;&#208;&#190;&#208;&#185; 3">
      <g id="Group">
        <g id="Group_2">
          <path
            id="Vector"
            d="M20.9999 41.5716C9.65677 41.5716 0.428467 32.3433 0.428467 21.0001C0.428467 9.65701 9.65677 0.428711 20.9999 0.428711C32.343 0.428711 41.5713 9.65701 41.5713 21.0001C41.5713 32.3433 32.343 41.5716 20.9999 41.5716ZM20.9999 2.63267C10.8732 2.63442 2.63417 10.8735 2.63417 21.0001C2.63417 31.1268 10.8732 39.3659 20.9999 39.3659C31.1266 39.3659 39.3656 31.1268 39.3656 21.0001C39.3656 10.8735 31.1266 2.63442 20.9999 2.63442V2.63267Z"
          />
        </g>
      </g>
      <path
        id="Vector (Stroke)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.25 21.0002C26.25 20.3558 26.7723 19.8335 27.4167 19.8335H28C28.6443 19.8335 29.1667 20.3558 29.1667 21.0002C29.1667 21.6445 28.6443 22.1668 28 22.1668H27.4167C26.7723 22.1668 26.25 21.6445 26.25 21.0002Z"
      />
      <path
        id="Vector (Stroke)_2"
        d="M25.6667 9.3335C26.311 9.3335 26.8333 9.85583 26.8333 10.5002C26.8333 11.06 26.9422 11.4338 27.083 11.7271C27.2358 12.0453 27.4433 12.3117 27.7443 12.688L27.768 12.7176L27.768 12.7176C28.0474 13.0666 28.4103 13.52 28.6872 14.0969C28.9839 14.715 29.1667 15.435 29.1667 16.3335C29.1667 16.9778 28.6443 17.5002 28 17.5002C27.3557 17.5002 26.8333 16.9778 26.8333 16.3335C26.8333 15.7736 26.7244 15.3999 26.5836 15.1066C26.4309 14.7884 26.2233 14.5219 25.9223 14.1456L25.8987 14.1161C25.6193 13.767 25.2564 13.3137 24.9795 12.7368C24.6828 12.1187 24.5 11.3986 24.5 10.5002C24.5 9.85583 25.0223 9.3335 25.6667 9.3335Z"
      />
      <path
        id="Vector (Stroke)_3"
        d="M30.3334 9.3335C30.9777 9.3335 31.5001 9.85583 31.5001 10.5002C31.5001 11.06 31.609 11.4338 31.7498 11.7271C31.9025 12.0453 32.1101 12.3117 32.4111 12.688L32.4348 12.7176L32.4348 12.7176C32.7141 13.0666 33.0771 13.52 33.3539 14.0969C33.6506 14.715 33.8334 15.435 33.8334 16.3335C33.8334 16.9778 33.3111 17.5002 32.6667 17.5002C32.0224 17.5002 31.5001 16.9778 31.5001 16.3335C31.5001 15.7736 31.3912 15.3999 31.2504 15.1066C31.0977 14.7884 30.8901 14.5219 30.5891 14.1456L30.5654 14.1161L30.5654 14.1161C30.286 13.767 29.9231 13.3136 29.6462 12.7368C29.3495 12.1187 29.1667 11.3986 29.1667 10.5002C29.1667 9.85583 29.6891 9.3335 30.3334 9.3335Z"
      />
      <path
        id="Vector (Stroke)_4"
        d="M31.5 21.0002C31.5 20.3558 32.0223 19.8335 32.6667 19.8335C33.311 19.8335 33.8333 20.3558 33.8333 21.0002V25.6668C33.8333 26.3112 33.311 26.8335 32.6667 26.8335C32.0223 26.8335 31.5 26.3112 31.5 25.6668V21.0002Z"
      />
      <path
        id="Subtract"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.1835 26.8333L31.8418 33.4916L33.3502 35L35 33.3502L8.65004 7L7 8.65004L8.50846 10.1583L18.1835 19.8333H9.33341C8.68908 19.8333 8.16675 20.3556 8.16675 21V25.6666C8.16675 26.311 8.68908 26.8333 9.33341 26.8333H25.1835ZM14 22.1666V24.5H10.5V22.1666H14ZM16.3333 22.1666H20.5168L22.8501 24.5H16.3333V22.1666Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SmokingFilterSvg',
};
</script>

<style scoped></style>
