<template>
  <div class="select-game-page d-flex flex-column">
    <div class="filter-block px-3 my-3">
      <BaseInput v-model="searchQuery" placeholder="placeholder.search" @input="debouncedInputHandler" />
    </div>
    <div class="search-items-list px-3">
      <GameListItem v-for="game in games" :key="game" :game-id="game" :ref="game" :suppressDetails="true"
        @click="selectGame(game)" :class="{ selected: selectedGame?.id === game }" class="bordered my-3 py-3 px-1" />
    </div>
    <div v-show="!!games.length" class="controls py-3 px-3">
      <BaseButton :disabled="!selectedGame" @click="backToEvent" class="primary"> {{ $t('button.select') }}</BaseButton>
    </div>
  </div>
</template>

<script>
import GameListItem from '@/components/GameListItem.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import ApiUtils from '@/utils/ApiUtils';
import { debounce } from 'lodash';

export default {
  name: 'SelectGamePage',
  components: {
    BaseInput,
    BaseButton,
    GameListItem,
  },
  data() {
    return {
      searchQuery: '',
      searchGames: [],
      debouncedInputHandler: debounce(this.searchItems, 400),
    };
  },
  computed: {
    myGames() {
      return this.$store.getters['UserStore/getFavoriteGames'];
    },
    games() {
      if (this.searchQuery) {
        return this.searchGames;
      } else {
        const allGames = [...this.myGames];
        for (const hotGame of this.hot50Games) {
          if (!allGames.includes(hotGame)) {
            allGames.push(hotGame);
          }
        }

        return allGames;
      }
    },
    hot50Games() {
      return this.$store.getters['SearchStore/getInitialGames'];
    },
    selectedGame() {
      return this.$store.getters['EventStore/getSelectedGame'];
    },
  },
  methods: {
    async searchItems() {
      if (!this.searchQuery) {
        this.searchGames = [];
      }
      const games = await ApiUtils.search(this.searchQuery);

      const gameIds = games.map((game) => game.id);

      for (const id of gameIds) {
        this.$store.commit('SearchStore/setGameDetails', {
          gameId: id,
          gameDetails: games.find((game) => game.id === id),
        });
      }

      this.searchGames = gameIds;
      window.console.log('searchGames', this.searchGames);
    },
    selectGame(game) {
      const gameDetails = this.$refs[game][0].gameDetails;
      this.$store.commit('EventStore/setSelectedGame', gameDetails);
    },
    backToEvent() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.select-game-page {
  height: 100%;
  overflow: hidden;

  .search-items-list {
    overflow-y: scroll;
  }

  .controls {
    margin-top: auto;
  }
}
</style>
